// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/my-app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/app/my-app/src/layouts/BasicLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "exact": true
      },
      {
        "path": "/manage-center",
        "name": "manage-center",
        "isTop": true,
        "routes": [
          {
            "path": "/manage-center/system-manager",
            "icon": "setting",
            "name": "system-manager",
            "hideInBreadcrumb": false,
            "routes": [
              {
                "path": "/manage-center/system-manager",
                "exact": true
              },
              {
                "path": "/manage-center/system-manager/user",
                "name": "user",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__User__UserList' */'/app/my-app/src/pages/ManageCenter/SystemManager/User/UserList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/system-manager/user/:userId/user-data-filter",
                "name": "user-data-filter",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__User__UserDataFilter' */'/app/my-app/src/pages/ManageCenter/SystemManager/User/UserDataFilter'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/manage-center/system-manager/user/:userId/user-data-filter",
                    "redirect": "/manage-center/system-manager/user/:userId/user-data-filter/region",
                    "exact": true
                  },
                  {
                    "path": "/manage-center/system-manager/user/:userId/user-data-filter/region",
                    "name": "region",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__User__UserDataFilter__Region' */'/app/my-app/src/pages/ManageCenter/SystemManager/User/UserDataFilter/Region'), loading: LoadingComponent}),
                    "meta": {
                      "name": "区域数据权限配置"
                    },
                    "exact": true
                  },
                  {
                    "path": "/manage-center/system-manager/user/:userId/user-data-filter/company",
                    "name": "company",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__User__UserDataFilter__Company' */'/app/my-app/src/pages/ManageCenter/SystemManager/User/UserDataFilter/Company'), loading: LoadingComponent}),
                    "meta": {
                      "name": "场所数据权限配置"
                    },
                    "exact": true
                  },
                  {
                    "path": "/manage-center/system-manager/user/:userId/user-data-filter/lampRole",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__User__UserDataFilter__LampRole' */'/app/my-app/src/pages/ManageCenter/SystemManager/User/UserDataFilter/LampRole'), loading: LoadingComponent}),
                    "meta": {
                      "name": "灯光厂家权限配置"
                    },
                    "exact": true
                  },
                  {
                    "path": "/manage-center/system-manager/user/:userId/user-data-filter/agent",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__User__UserDataFilter__Agent' */'/app/my-app/src/pages/ManageCenter/SystemManager/User/UserDataFilter/Agent'), loading: LoadingComponent}),
                    "meta": {
                      "name": "代理商权限配置"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/manage-center/system-manager/role",
                "name": "role",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__Role__RoleList' */'/app/my-app/src/pages/ManageCenter/SystemManager/Role/RoleList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/system-manager/menu",
                "name": "menu",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__Menu__MenuList' */'/app/my-app/src/pages/ManageCenter/SystemManager/Menu/MenuList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/system-manager/configuration",
                "name": "configuration",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__Configuration' */'/app/my-app/src/pages/ManageCenter/SystemManager/Configuration'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/system-manager/job",
                "name": "job",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__Job' */'/app/my-app/src/pages/ManageCenter/SystemManager/Job'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/system-manager/deviceActionsManage",
                "name": "deviceActionsManage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__SystemManager__DeviceActionsManage' */'/app/my-app/src/pages/ManageCenter/SystemManager/DeviceActionsManage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/manage-center/operation-maintenance-center",
            "icon": "setting",
            "name": "operation-maintenance-center",
            "hideInBreadcrumb": false,
            "routes": [
              {
                "path": "/manage-center/operation-maintenance-center/verify-test-companies",
                "name": "verify-test-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__VerifyTestCompanies__' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/VerifyTestCompanies/'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/operation-maintenance-center/distribution-strategy",
                "name": "distribution-strategy",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__DistributionStrategy__' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/DistributionStrategy/'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/operation-maintenance-center/distribution-strategy/create",
                "name": "distribution-strategy-create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__DistributionStrategy__create' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/DistributionStrategy/create'), loading: LoadingComponent}),
                "meta": {
                  "name": "创建策略信息"
                },
                "exact": true
              },
              {
                "path": "/manage-center/operation-maintenance-center/distribution-strategy/edit/:distribution_strategy_id",
                "name": "distribution-strategy-edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__DistributionStrategy__create' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/DistributionStrategy/create'), loading: LoadingComponent}),
                "meta": {
                  "name": "编辑策略信息"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/manage-center/account",
            "icon": "setting",
            "name": "account",
            "hideInBreadcrumb": false,
            "routes": [
              {
                "path": "/manage-center/account",
                "exact": true
              },
              {
                "path": "/manage-center/account/settings",
                "name": "settings",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings__Info' */'/app/my-app/src/pages/Account/Settings/Info'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/manage-center/account/settings",
                    "redirect": "/manage-center/account/settings/base",
                    "exact": true
                  },
                  {
                    "path": "/manage-center/account/settings/base",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings__BaseView' */'/app/my-app/src/pages/Account/Settings/BaseView'), loading: LoadingComponent}),
                    "meta": {
                      "name": "基本设置"
                    },
                    "exact": true
                  },
                  {
                    "path": "/manage-center/account/settings/security",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings__SecurityView' */'/app/my-app/src/pages/Account/Settings/SecurityView'), loading: LoadingComponent}),
                    "meta": {
                      "name": "安全设置"
                    },
                    "exact": true
                  },
                  {
                    "path": "/manage-center/account/settings/message",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Settings__MessageView' */'/app/my-app/src/pages/Account/Settings/MessageView'), loading: LoadingComponent}),
                    "meta": {
                      "name": "消息设置"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/manage-center/account/messges",
                "name": "message-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__MessageManager__Messages' */'/app/my-app/src/pages/ManageCenter/MessageManager/Messages'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/manage-center/message-manager",
            "icon": "setting",
            "name": "message-manager",
            "hideInBreadcrumb": false,
            "routes": [
              {
                "path": "/manage-center/message-manager/",
                "redirect": "/manage-center/message-manager/message-template",
                "exact": true
              },
              {
                "path": "/manage-center/message-manager/message-template",
                "name": "message-template",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__MessageManager__MessageTemplate' */'/app/my-app/src/pages/ManageCenter/MessageManager/MessageTemplate'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/message-manager/message-template/create",
                "name": "message-template-create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__MessageManager__MessageTemplateForm' */'/app/my-app/src/pages/ManageCenter/MessageManager/MessageTemplateForm'), loading: LoadingComponent}),
                "meta": {
                  "name": "创建模版"
                },
                "exact": true
              },
              {
                "path": "/manage-center/message-manager/message-template/edit/:id",
                "name": "message-template-edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__MessageManager__MessageTemplateForm' */'/app/my-app/src/pages/ManageCenter/MessageManager/MessageTemplateForm'), loading: LoadingComponent}),
                "meta": {
                  "name": "更新模版"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/manage-center/app-manager",
            "routes": [
              {
                "path": "/manage-center/app-manager/app-menus",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__AppManager__appMenus' */'/app/my-app/src/pages/ManageCenter/AppManager/appMenus'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/app-manager/fault-tree",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__AppManager__faultTree' */'/app/my-app/src/pages/ManageCenter/AppManager/faultTree'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/app-manager/install-work-order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__AppManager__installWorkOrder' */'/app/my-app/src/pages/ManageCenter/AppManager/installWorkOrder'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/app-manager/company-mobile-menus",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__AppManager__companyMobileMenus' */'/app/my-app/src/pages/ManageCenter/AppManager/companyMobileMenus'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/manage-center/platform-manager",
            "routes": [
              {
                "path": "/manage-center/platform-manager/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager' */'/app/my-app/src/pages/ManageCenter/PlatformManager'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/platform-manager/agent",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager__agentManage__layout' */'/app/my-app/src/pages/ManageCenter/PlatformManager/agentManage/layout'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/manage-center/platform-manager/agent/menus",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager__agentManage__menuSetting' */'/app/my-app/src/pages/ManageCenter/PlatformManager/agentManage/menuSetting'), loading: LoadingComponent}),
                    "meta": {
                      "name": "菜单管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/manage-center/platform-manager/agent/role-template",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager__agentManage__roleTemplate' */'/app/my-app/src/pages/ManageCenter/PlatformManager/agentManage/roleTemplate'), loading: LoadingComponent}),
                    "meta": {
                      "name": "角色模板"
                    },
                    "exact": true
                  },
                  {
                    "path": "/manage-center/platform-manager/agent/menu-template",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager__agentManage__menuTemplate' */'/app/my-app/src/pages/ManageCenter/PlatformManager/agentManage/menuTemplate'), loading: LoadingComponent}),
                    "meta": {
                      "name": "菜单模板"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/manage-center/platform-manager/company",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager__companyManage__layout' */'/app/my-app/src/pages/ManageCenter/PlatformManager/companyManage/layout'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/manage-center/platform-manager/company/menus",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager__companyManage__menuSetting' */'/app/my-app/src/pages/ManageCenter/PlatformManager/companyManage/menuSetting'), loading: LoadingComponent}),
                    "meta": {
                      "name": "菜单管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/manage-center/platform-manager/company/user-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager__companyManage__userList' */'/app/my-app/src/pages/ManageCenter/PlatformManager/companyManage/userList'), loading: LoadingComponent}),
                    "meta": {
                      "name": "商家平台用户列表"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/manage-center/platform-manager/partner",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager__partnerManage__layout' */'/app/my-app/src/pages/ManageCenter/PlatformManager/partnerManage/layout'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/manage-center/platform-manager/partner/menus",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager__partnerManage__menuSetting' */'/app/my-app/src/pages/ManageCenter/PlatformManager/partnerManage/menuSetting'), loading: LoadingComponent}),
                    "meta": {
                      "name": "菜单管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/manage-center/platform-manager/partner/menu-assign",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__PlatformManager__partnerManage__menuAssign' */'/app/my-app/src/pages/ManageCenter/PlatformManager/partnerManage/menuAssign'), loading: LoadingComponent}),
                    "meta": {
                      "name": "菜单分配"
                    },
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "path": "/manage-center/information-manager",
            "icon": "setting",
            "name": "information-manager",
            "hideInBreadcrumb": false,
            "routes": [
              {
                "path": "/manage-center/information-manager/",
                "redirect": "/manage-center/information-manager/information-list",
                "exact": true
              },
              {
                "path": "/manage-center/information-manager/information-setting",
                "name": "information-setting",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__InformationManager__informationSetting' */'/app/my-app/src/pages/ManageCenter/InformationManager/informationSetting'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/manage-center/information-manager/information-list",
                "name": "information-setting",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ManageCenter__InformationManager__informationList' */'/app/my-app/src/pages/ManageCenter/InformationManager/informationList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/data-center",
        "name": "data-center",
        "isTop": true,
        "routes": [
          {
            "path": "/data-center/dashboard",
            "name": "dashboard",
            "icon": "dashboard",
            "hideInBreadcrumb": true,
            "routes": [
              {
                "path": "/data-center/dashboard/",
                "redirect": "/data-center/dashboard/ktv-system",
                "exact": true
              },
              {
                "path": "/data-center/dashboard/ktv-system",
                "name": "ktv-system",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__SystemOverview__KtvSystem' */'/app/my-app/src/pages/DataCenter/SystemOverview/KtvSystem'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/dashboard/management-system",
                "name": "management-system",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__SystemOverview__ManagementSystem' */'/app/my-app/src/pages/DataCenter/SystemOverview/ManagementSystem'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/dashboard/company-device",
                "name": "fs-company-device",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__FullScreen__CompanyDeviceInfo' */'/app/my-app/src/pages/DataCenter/FullScreen/CompanyDeviceInfo'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/dashboard/theme-data/merchant",
                "name": "theme-data.merchant",
                "meta": {
                  "name": "商家主题详情"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__Dashboard__ThemeResourceService__merchant' */'/app/my-app/src/pages/DataCenter/Dashboard/ThemeResourceService/merchant'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/dashboard/theme-data",
                "name": "theme-data",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__Dashboard__ThemeResourceService' */'/app/my-app/src/pages/DataCenter/Dashboard/ThemeResourceService'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/dashboard/theme-data/place-data",
                "name": "/theme-data/place-data",
                "meta": {
                  "name": "场所详情"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__Dashboard__ThemeResourceService__place' */'/app/my-app/src/pages/DataCenter/Dashboard/ThemeResourceService/place'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/dashboard/fee-production",
                "name": "fs-fee-production",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__FullScreen__FeeProductionInfo' */'/app/my-app/src/pages/DataCenter/FullScreen/FeeProductionInfo'), loading: LoadingComponent}),
                "meta": {
                  "name": "付费项目数据(旧版)"
                },
                "exact": true
              },
              {
                "path": "/data-center/dashboard/cdn-product-bi-analysis",
                "name": "cdn-product-bi-analysis",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__FullScreen__CDNProductBIAnalysis' */'/app/my-app/src/pages/DataCenter/FullScreen/CDNProductBIAnalysis'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/dashboard/cdn-product-bi-analysis/list",
                "name": "cdn-product-bi-analysis-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__FullScreen__CDNProductBIAnalysis__companyList' */'/app/my-app/src/pages/DataCenter/FullScreen/CDNProductBIAnalysis/companyList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/dashboard/table",
                "name": "common-table",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__FullScreen__CommonTable' */'/app/my-app/src/pages/DataCenter/FullScreen/CommonTable'), loading: LoadingComponent}),
                "meta": {
                  "name": "付费项目数据(旧版)"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/data-center/data-cleansing",
            "name": "data-cleansing",
            "icon": "shop",
            "hideInBreadcrumb": true,
            "routes": [
              {
                "path": "/data-center/data-cleansing/",
                "redirect": "/data-center/data-cleansing/data-analysis",
                "exact": true
              },
              {
                "path": "/data-center/data-cleansing/data-analysis",
                "name": "data-analysis",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataCleansing__DataAnalysis' */'/app/my-app/src/pages/DataCenter/DataCleansing/DataAnalysis'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/data-cleansing/data-obk-venn",
                "name": "data-obk-venn",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataCleansing__DataOBKVenn' */'/app/my-app/src/pages/DataCenter/DataCleansing/DataOBKVenn'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/data-cleansing/abnormal-heart-company",
                "name": "abnormal-heart-company",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataCleansing__AbnormalHeartCompany' */'/app/my-app/src/pages/DataCenter/DataCleansing/AbnormalHeartCompany'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/data-cleansing/abnormal-heart-company/abnormal-type",
                "name": "abnormal-heart-company-abnormal-type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__DataCleansing__AbnormalHeartCompany__AbnormalType' */'/app/my-app/src/pages/DataCenter/DataCleansing/AbnormalHeartCompany/AbnormalType'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/data-center/company-analyze",
            "name": "company-analyze",
            "icon": "shop",
            "hideInBreadcrumb": true,
            "routes": [
              {
                "path": "/data-center/company-analyze/",
                "redirect": "/data-center/company-analyze/km-companies",
                "exact": true
              },
              {
                "path": "/data-center/company-analyze/km-companies",
                "name": "km-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__CompanyAnalyze__KmCompanies' */'/app/my-app/src/pages/DataCenter/CompanyAnalyze/KmCompanies'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/company-analyze/baobei-companies",
                "name": "baobei-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__CompanyAnalyze__BaobeiCompanies' */'/app/my-app/src/pages/DataCenter/CompanyAnalyze/BaobeiCompanies'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/company-analyze/sale-authorization-companies",
                "name": "sale-authorization-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__CompanyAnalyze__SaleAuthorizationCompanies' */'/app/my-app/src/pages/DataCenter/CompanyAnalyze/SaleAuthorizationCompanies'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/company-analyze/sale-authorization-management-companies",
                "name": "sale-authorization-management-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__CompanyAnalyze__SaleAuthorizationManagementCompanies' */'/app/my-app/src/pages/DataCenter/CompanyAnalyze/SaleAuthorizationManagementCompanies'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/data-center/device-analyze",
            "name": "device-analyze",
            "icon": "api",
            "hideInBreadcrumb": true,
            "routes": [
              {
                "path": "/data-center/device-analyze/",
                "redirect": "/data-center/device-analyze/ktv-system-analyze",
                "exact": true
              },
              {
                "path": "/data-center/device-analyze/ktv-system-analyze",
                "name": "ktv-system-analyze",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__SystemOverview__DeviceAnalyze__KTVSystemAnalyze' */'/app/my-app/src/pages/DataCenter/SystemOverview/DeviceAnalyze/KTVSystemAnalyze'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/data-center/device-analyze/management-system-analyze",
                "name": "management-system-analyze",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__SystemOverview__DeviceAnalyze__ManagementSystemAnalyze' */'/app/my-app/src/pages/DataCenter/SystemOverview/DeviceAnalyze/ManagementSystemAnalyze'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/data-center/features-use-analyze",
            "name": "features-use-analyze",
            "icon": "tool",
            "hideInBreadcrumb": true,
            "routes": [
              {
                "path": "/data-center/features-use-analyze/",
                "redirect": "/data-center/features-use-analyze/management-analyze",
                "exact": true
              },
              {
                "path": "/data-center/features-use-analyze/management-analyze",
                "name": "management-analyze",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__FeaturesUseAnalyze__ManagementAnalyze' */'/app/my-app/src/pages/DataCenter/FeaturesUseAnalyze/ManagementAnalyze'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/data-center/company-error-log",
            "icon": "tool",
            "routes": [
              {
                "path": "/data-center/company-error-log/",
                "redirect": "/data-center/company-error-log/log-analysis-task",
                "exact": true
              },
              {
                "path": "/data-center/company-error-log/log-analysis-task",
                "routes": [
                  {
                    "path": "/data-center/company-error-log/log-analysis-task/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__CompanyErrorLog__LogAnalysisTask' */'/app/my-app/src/pages/DataCenter/CompanyErrorLog/LogAnalysisTask'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/data-center/company-error-log/log-analysis-task/log-analysis-task-detail/:taskId",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataCenter__CompanyErrorLog__LogAnalysisTaskDetail' */'/app/my-app/src/pages/DataCenter/CompanyErrorLog/LogAnalysisTaskDetail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "path": "/operation-maintenance-center",
        "name": "operation-maintenance-center",
        "routes": [
          {
            "path": "/operation-maintenance-center/company-list",
            "name": "company-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyList'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/operation-maintenance-center/company-list/",
                "redirect": "/operation-maintenance-center/company-list/net-companies",
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-list/net-companies",
                "name": "net-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__NetCompanies' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/NetCompanies'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId",
                "routes": [
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/boxes/devices",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__CompanyDevicesList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/CompanyDevicesList'), loading: LoadingComponent}),
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/boxes/devices/boxlinks/:id",
                        "name": "company-boxlinks",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyBoxSystem' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyBoxSystem'), loading: LoadingComponent}),
                        "meta": {
                          "name": "包厢链路情况"
                        },
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/gateway/:deviceType/:productionId/:companyId/:companyCode/wg-remote-desktop",
                    "name": "wg-remote-desktop",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteDesktop__RemoteDesktop' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteDesktop/RemoteDesktop'), loading: LoadingComponent}),
                    "meta": {
                      "name": "网关远程桌面"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/fengyun/:deviceType/:productionId/:companyId/:companyCode/fy-remote-desktop",
                    "name": "fy-remote-desktop",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteDesktop__RemoteDesktop' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteDesktop/RemoteDesktop'), loading: LoadingComponent}),
                    "meta": {
                      "name": "锋云远程桌面"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices",
                    "name": "devices",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__CompanyDevicesList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/CompanyDevicesList'), loading: LoadingComponent}),
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/stb",
                        "name": "stb",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Stb' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Stb'), loading: LoadingComponent}),
                        "meta": {
                          "name": "机顶盒"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/fengyun",
                        "name": "fengyun",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Fengyun' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Fengyun'), loading: LoadingComponent}),
                        "meta": {
                          "name": "锋云"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/gateway",
                        "name": "gateway",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Gateway' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Gateway'), loading: LoadingComponent}),
                        "meta": {
                          "name": "网关"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/management",
                        "name": "management",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Management' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Management'), loading: LoadingComponent}),
                        "meta": {
                          "name": "管理系统"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/mpj",
                        "name": "mpj",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Mpj' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Mpj'), loading: LoadingComponent}),
                        "meta": {
                          "name": "门牌机"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/ddj",
                        "name": "ddj",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Ddj' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Ddj'), loading: LoadingComponent}),
                        "meta": {
                          "name": "自主点单机"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/zk",
                        "name": "zk",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Zk' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Zk'), loading: LoadingComponent}),
                        "meta": {
                          "name": "智控"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/camera",
                        "name": "camera",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Camera' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Camera'), loading: LoadingComponent}),
                        "meta": {
                          "name": "摄像头"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/mic",
                        "name": "mic",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Mic' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Mic'), loading: LoadingComponent}),
                        "meta": {
                          "name": "智能麦克风"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/voice-box",
                        "name": "voice-box",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__VoiceBox' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/VoiceBox'), loading: LoadingComponent}),
                        "meta": {
                          "name": "语音盒"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/pad",
                        "name": "pad",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Pad' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Pad'), loading: LoadingComponent}),
                        "meta": {
                          "name": "Pad"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/yczy",
                        "name": "yzcy",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Yczy' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Yczy'), loading: LoadingComponent}),
                        "meta": {
                          "name": "远程资源"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/management-peripherals",
                        "name": "management-peripherals",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__ManagementPeripherals' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/ManagementPeripherals'), loading: LoadingComponent}),
                        "meta": {
                          "name": "管理系统外设"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview/devices/rsbox",
                        "name": "rsbox",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Rsbox' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Rsbox'), loading: LoadingComponent}),
                        "meta": {
                          "name": "全景服务器"
                        },
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail",
                    "name": "detail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyDetail' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyDetail'), loading: LoadingComponent}),
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/info",
                        "name": "info",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyDetailInfo' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyDetailInfo'), loading: LoadingComponent}),
                        "meta": {
                          "name": "场所详情"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/overview",
                        "name": "company-devices-overview",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyDevicesOverview' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyDevicesOverview'), loading: LoadingComponent}),
                        "meta": {
                          "name": "设备概览"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/boxes",
                        "name": "company-boxes",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyBoxes' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyBoxes'), loading: LoadingComponent}),
                        "meta": {
                          "name": "包厢列表"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/companylinks",
                        "name": "company-links",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyTopology' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyTopology'), loading: LoadingComponent}),
                        "meta": {
                          "name": "场所设备链路情况"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/sub-store-list",
                        "name": "sub-store-list",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__SubCompanyList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/SubCompanyList'), loading: LoadingComponent}),
                        "meta": {
                          "name": "分店列表"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/managementsqlInjection",
                        "name": "company-managementsqlInjection",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__ManagementsqlInjection' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/ManagementsqlInjection'), loading: LoadingComponent}),
                        "meta": {
                          "name": "管理系统远程操控"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/company-song-lib-manage/:companyCode",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__companySongLibManage' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/companySongLibManage'), loading: LoadingComponent}),
                        "meta": {
                          "name": "场所曲库管理"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/detail/company-song-lib-manage/:companyCode/delete-song-log",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__companyDeleteSongLogs' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/companyDeleteSongLogs'), loading: LoadingComponent}),
                        "meta": {
                          "name": "删歌日志"
                        },
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/fengyun/:id",
                    "name": "fengyun",
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/fengyun/:id/files",
                        "name": "files",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__Fengyun__Files' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/Fengyun/Files'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/fengyun/:id/configuration",
                        "name": "configuration",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__Fengyun__Configuration' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/Fengyun/Configuration'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/fengyun/:id/module-upgrade",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__Fengyun__moduleUpgrade' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/Fengyun/moduleUpgrade'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/fengyun/:id/module-upgrade/upgrade-history",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__Fengyun__upgradeHistory' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/Fengyun/upgradeHistory'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/fengyun/:id/delete-repeating-song-log",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__Fengyun__DeleteRepeatingSongLog' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/Fengyun/DeleteRepeatingSongLog'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/gateway/:id",
                    "name": "gateway",
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/gateway/:id/files",
                        "name": "files",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__Gateway__Files' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/Gateway/Files'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/management/:id",
                    "name": "management",
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/management/:id/peripherals",
                        "name": "peripherals",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__ManagementPage__Peripherals' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/ManagementPage/Peripherals'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/product/:device_type/changelog/:id",
                    "name": "changelog",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__ChangeLog' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/ChangeLog'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/product/:device_type/abnormal/:production_id",
                    "name": "abnormal",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__Abnormal' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/Abnormal'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/product/:device_type/loglist/:production_id",
                    "name": "loglist",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__LogList' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/LogList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/company-list/net-companies/company/:companyId/product/:device_type/stb-log-list/:production_id/id/:id",
                    "name": "stb-log-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__StbLogList' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/StbLogList'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/search",
            "name": "search",
            "routes": [
              {
                "path": "/operation-maintenance-center/search/",
                "redirect": "/operation-maintenance-center/search/devices",
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/search/devices",
                "name": "devices",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Search__Devices' */'/app/my-app/src/pages/OperationMaintenanceCenter/Search/Devices'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/search/devices/company/:companyId",
                "name": "company",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/operation-maintenance-center/search/devices/company/:companyId/detail",
                    "name": "detail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyDetail' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyDetail'), loading: LoadingComponent}),
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/detail/info",
                        "name": "info",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyDetailInfo' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyDetailInfo'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/detail/overview",
                        "name": "company-devices-overview",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyDevicesOverview' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyDevicesOverview'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/detail/boxes",
                        "name": "company-boxes",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyBoxes' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyBoxes'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/detail/companylinks",
                        "name": "company-links",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyTopology' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyTopology'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/detail/boxlinks/:id",
                        "name": "company-boxlinks",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__CompanyBoxSystem' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/CompanyBoxSystem'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/detail/sub-store-list",
                        "name": "sub-store-list",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDetail__SubCompanyList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDetail/SubCompanyList'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/search/devices/company/:companyId/devices",
                    "name": "devices",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__CompanyDevicesList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/CompanyDevicesList'), loading: LoadingComponent}),
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/stb",
                        "name": "stb",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Stb' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Stb'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/fengyun",
                        "name": "fengyun",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Fengyun' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Fengyun'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/gateway",
                        "name": "gateway",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Gateway' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Gateway'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/management",
                        "name": "management",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Management' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Management'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/mpj",
                        "name": "mpj",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Mpj' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Mpj'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/ddj",
                        "name": "ddj",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Ddj' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Ddj'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/zk",
                        "name": "zk",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Zk' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Zk'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/camera",
                        "name": "camera",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Camera' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Camera'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/mic",
                        "name": "mic",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Mic' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Mic'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/voice-box",
                        "name": "voice-box",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__VoiceBox' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/VoiceBox'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/yczy",
                        "name": "yzcy",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Yczy' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Yczy'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/management-peripherals",
                        "name": "management-peripherals",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__ManagementPeripherals' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/ManagementPeripherals'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/devices/rsbox",
                        "name": "rsbox",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyList__CompanyDevicesList__Rsbox' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyList/CompanyDevicesList/Rsbox'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/search/devices/company/:companyId/fengyun/:id",
                    "name": "fengyun",
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/fengyun/:id/files",
                        "name": "files",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__Fengyun__Files' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/Fengyun/Files'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/search/devices/company/:companyId/gateway/:id",
                    "name": "gateway",
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/gateway/:id/files",
                        "name": "files",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__Gateway__Files' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/Gateway/Files'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/search/devices/company/:companyId/management/:id",
                    "name": "management",
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/search/devices/company/:companyId/management/:id/peripherals",
                        "name": "peripherals",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__ManagementPage__Peripherals' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/ManagementPage/Peripherals'), loading: LoadingComponent}),
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/search/devices/company/:companyId/product/:device_type/changelog/:id",
                    "name": "changelog",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__ChangeLog' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/ChangeLog'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/search/devices/company/:companyId/product/:device_type/abnormal/:production_id",
                    "name": "abnormal",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Devices__Abnormal' */'/app/my-app/src/pages/OperationMaintenanceCenter/Devices/Abnormal'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/operation-maintenance-center/search/device-exceptions",
                "name": "device-exceptions",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__Search__DeviceExceptions' */'/app/my-app/src/pages/OperationMaintenanceCenter/Search/DeviceExceptions'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/app-stb-adapter",
            "name": "app-stb-adapter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppStbAdapter' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppStbAdapter'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/operation-maintenance-center/app-stb-adapter/",
                "redirect": "/operation-maintenance-center/app-stb-adapter/firmware",
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/app-stb-adapter/firmware",
                "name": "firmware",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppStbAdapter__Firmware' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppStbAdapter/Firmware'), loading: LoadingComponent}),
                "meta": {
                  "name": "固件"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/app-stb-adapter/main-app-group",
                "name": "main-app-group",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppStbAdapter__Software' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppStbAdapter/Software'), loading: LoadingComponent}),
                "meta": {
                  "name": "主应用"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/app-stb-adapter/hardware-model",
                "name": "hardware-model",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppStbAdapter__Hardware' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppStbAdapter/Hardware'), loading: LoadingComponent}),
                "meta": {
                  "name": "硬件"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/company-app-manager",
            "name": "company-app-manager",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppManager' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppManager'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list",
                "name": "app-base-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppBase__AppBaseList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppBase/AppBaseList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/app-base-create",
                "name": "app-base-create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppBaseDetail' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppBaseDetail'), loading: LoadingComponent}),
                "meta": {
                  "name": "创建应用"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/app-base-detail/:baseId",
                "name": "app-base-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppBaseDetail' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppBaseDetail'), loading: LoadingComponent}),
                "meta": {
                  "name": "参数详情"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/:baseAppId/version/:appVersionId/local-release",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppVersion__LocalRelease' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppVersion/LocalRelease'), loading: LoadingComponent}),
                "meta": {
                  "name": "区域发布"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/:baseAppId/version-create",
                "name": "version-create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppVersion__CompanyAppCreateForm' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppVersion/CompanyAppCreateForm'), loading: LoadingComponent}),
                "meta": {
                  "name": "创建版本"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/:baseAppId/version-list-info/:manufacturer/version-create",
                "name": "version-create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppVersion__CompanyAppCreateForm' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppVersion/CompanyAppCreateForm'), loading: LoadingComponent}),
                "meta": {
                  "name": "创建版本"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/:baseAppId/version-list-info/:manufacturer",
                "name": "version-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppVersionList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppVersionList'), loading: LoadingComponent}),
                "meta": {
                  "name": "版本列表"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/:baseAppId/version-list-info/version/:appVersionId/local-release/:appVersionStatus",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppVersion__LocalRelease' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppVersion/LocalRelease'), loading: LoadingComponent}),
                "meta": {
                  "name": "区域发布"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/:baseAppId/version-list-info/:manufacturer/version/:appVersionId/log",
                "name": "version-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppVersion__AppVersionLog' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppVersion/AppVersionLog'), loading: LoadingComponent}),
                "meta": {
                  "name": "发布日志"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/:baseAppId/version-list-info/version-detail-info/:appVersionId",
                "name": "version-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppVersion__CompanyAppDetail' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppVersion/CompanyAppDetail'), loading: LoadingComponent}),
                "meta": {
                  "name": "版本详情"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/:baseAppId/version-list-info/version/:appVersionId/:packageName/:versionNo",
                "name": "version",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppVersion__AppVersionDetail' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppVersion/AppVersionDetail'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/operation-maintenance-center/company-app-manager/app-base-list/:baseAppId/version-list-info/version/:appVersionId/:packageName/:versionNo/log/:logId/companies",
                    "name": "log-companies",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppVersion__StatusCompanies' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppVersion/StatusCompanies'), loading: LoadingComponent}),
                    "meta": {
                      "name": "场所日志"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-base-list/:baseAppId/version-list-info/:manufacturer/version/:appVersionId/log/:versionId/installed/companies",
                "name": "install-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppVersion__InstallCompanies' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppVersion/InstallCompanies'), loading: LoadingComponent}),
                "meta": {
                  "name": "升级商家"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/net-companies",
                "name": "app-net-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppNetCompanies' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppNetCompanies'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/net-companies/companyApp/:companyId",
                "name": "companyApp",
                "routes": [
                  {
                    "path": "/operation-maintenance-center/company-app-manager/net-companies/companyApp/:companyId/appDetail/stbs/stbDeviceApp/:deviceId",
                    "name": "stb-device-app",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__CompanyAppDetail__StbDeviceApp' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/CompanyAppDetail/StbDeviceApp'), loading: LoadingComponent}),
                    "meta": {
                      "name": "应用列表"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/company-app-manager/net-companies/companyApp/:companyId/appDetail",
                    "name": "appDetail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__CompanyAppDetail__CompanyAppDetail' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/CompanyAppDetail/CompanyAppDetail'), loading: LoadingComponent}),
                    "routes": [
                      {
                        "path": "/operation-maintenance-center/company-app-manager/net-companies/companyApp/:companyId/appDetail/overview",
                        "name": "company-app-overview",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__CompanyAppDetail__CompanyAppOverview' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/CompanyAppDetail/CompanyAppOverview'), loading: LoadingComponent}),
                        "meta": {
                          "name": "应用安装概览"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-app-manager/net-companies/companyApp/:companyId/appDetail/stbs",
                        "name": "app-boxes",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__CompanyAppDetail__CompanyStbs' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/CompanyAppDetail/CompanyStbs'), loading: LoadingComponent}),
                        "meta": {
                          "name": "机顶盒应用"
                        },
                        "exact": true
                      },
                      {
                        "path": "/operation-maintenance-center/company-app-manager/net-companies/companyApp/:companyId/appDetail/appInstallLog",
                        "name": "app-install-log",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__CompanyAppDetail__AppInstallLog' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/CompanyAppDetail/AppInstallLog'), loading: LoadingComponent}),
                        "meta": {
                          "name": "应用安装日志"
                        },
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/operation-maintenance-center/company-app-manager/net-companies/companyApp/:companyId/appDetail/stbs/stbDeviceAppLog/:deviceId",
                    "name": "stb-device-app-log",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__CompanyAppDetail__StbDeviceAppLog' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/CompanyAppDetail/StbDeviceAppLog'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/manu-facturer",
                "name": "manufacturer",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppStbAdapter__Manufacturer' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppStbAdapter/Manufacturer'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-review",
                "name": "app-review",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppReview__AppReviewList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppReview/AppReviewList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-review/app-review-detail-en/:ID",
                "name": "app-review-detail-en",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppReview__AppReviewDetailEN' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppReview/AppReviewDetailEN'), loading: LoadingComponent}),
                "meta": {
                  "name": "工单详情"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/hardware-application",
                "name": "hardware-application",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__HardwarePluginManage__HardwareApplication' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/HardwarePluginManage/HardwareApplication'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-version-run-environment",
                "name": "app-version-run-environments-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppRunEnvManage__AppRunEnvList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppRunEnvManage/AppRunEnvList'), loading: LoadingComponent}),
                "meta": {
                  "name": "运行环境"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-version-run-environment/create",
                "name": "app-version-run-environment-create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppRunEnvManage__AppRunEnvForm' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppRunEnvManage/AppRunEnvForm'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/company-app-manager/app-version-run-environment/edit/:id",
                "name": "app-version-run-environment-edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CompanyAppManager__AppRunEnvManage__AppRunEnvForm' */'/app/my-app/src/pages/OperationMaintenanceCenter/CompanyAppManager/AppRunEnvManage/AppRunEnvForm'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/maintexnance-system",
            "name": "maintenance-system",
            "icon": "tool",
            "routes": [
              {
                "path": "/operation-maintenance-center/maintexnance-system/feedback",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__MaintexnanceSystem__Feedback' */'/app/my-app/src/pages/OperationMaintenanceCenter/MaintexnanceSystem/Feedback'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/remote-upgrade",
            "name": "remote-upgrade",
            "icon": "cloud-upload",
            "hideInBreadcrumb": true,
            "routes": [
              {
                "path": "/operation-maintenance-center/remote-upgrade/upgrade-blacklist-companies",
                "name": "upgrade-blacklist-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__UpgradeBlackListCompanies__' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/UpgradeBlackListCompanies/'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-target-types",
                "name": "upgrade-packages-target-types",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__UpgradePackagesTargetTypes__' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/UpgradePackagesTargetTypes/'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr",
                "name": "upgrade-packages-mgr",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__UpgradePackagesMgr__index' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/UpgradePackagesMgr/index.jsx'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/upgrade-package/create",
                "name": "upgrade-package-create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__UpgradePackageBulid' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/UpgradePackageBulid'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/upgrade-package/create/",
                    "redirect": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/upgrade-package/create/base-info",
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/upgrade-package/create/base-info",
                    "name": "upgrade-package-base-info",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__UpgradePackageBulid__baseInfo' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/UpgradePackageBulid/baseInfo'), loading: LoadingComponent}),
                    "meta": {
                      "name": "升级包基本信息"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/upgrade-package/create/:upgrade_package_id/env-dependent-config",
                    "name": "upgrade-package-env-dependent-config",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__UpgradePackageBulid__envDependentConfig' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/UpgradePackageBulid/envDependentConfig'), loading: LoadingComponent}),
                    "meta": {
                      "name": "升级包环境依赖"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/upgrade-package/create/result",
                    "name": "upgrade-package-result",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__UpgradePackageBulid__result' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/UpgradePackageBulid/result'), loading: LoadingComponent}),
                    "meta": {
                      "name": "完成"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/verify-companies/:upgrade_package_id",
                "name": "verify-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__VerifyCompanies__' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/VerifyCompanies/'), loading: LoadingComponent}),
                "meta": {
                  "name": "管理验证场所"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/upgrade-package/:upgrade_package_id/edit",
                "name": "upgrade-package-edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__UpgradePackageBulid__edit' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/UpgradePackageBulid/edit'), loading: LoadingComponent}),
                "meta": {
                  "name": "编辑升级包"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/pilot-companies/:upgrade_package_id",
                "name": "pilot-companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__PilotCompanies__' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/PilotCompanies/'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/pilot-companies/:upgrade_package_id/",
                    "redirect": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/pilot-companies/:upgrade_package_id/specific",
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/pilot-companies/:upgrade_package_id/specific",
                    "name": "pilot-grounding-strategies-specific",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__PilotCompanies__specific' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/PilotCompanies/specific'), loading: LoadingComponent}),
                    "meta": {
                      "name": "指定升级"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/pilot-companies/:upgrade_package_id/batch-task",
                    "name": "pilot-batch-task",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__PilotCompanies__batchTaskList' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/PilotCompanies/batchTaskList'), loading: LoadingComponent}),
                    "meta": {
                      "name": "按筛选项任务列表"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/grounding-strategies/:upgrade_package_id",
                "name": "grounding-strategies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__GroundingStrategies__' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/GroundingStrategies/'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/grounding-strategies/:upgrade_package_id/",
                    "redirect": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/grounding-strategies/:upgrade_package_id/list",
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/grounding-strategies/:upgrade_package_id/list",
                    "name": "grounding-strategies-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__GroundingStrategies__list' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/GroundingStrategies/list'), loading: LoadingComponent}),
                    "meta": {
                      "name": "自动升级"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/grounding-strategies/:upgrade_package_id/create",
                    "name": "grounding-strategies-create",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__GroundingStrategies__create' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/GroundingStrategies/create'), loading: LoadingComponent}),
                    "meta": {
                      "name": "新增上架策略"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/grounding-strategies/:upgrade_package_id/specific",
                    "name": "grounding-strategies-specific",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__GroundingStrategies__specific' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/GroundingStrategies/specific'), loading: LoadingComponent}),
                    "meta": {
                      "name": "指定升级"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/grounding-strategies/:upgrade_package_id/shelves-jobs",
                    "name": "shelves-jobs",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__GroundingStrategies__shelvesJobs' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/GroundingStrategies/shelvesJobs'), loading: LoadingComponent}),
                    "meta": {
                      "name": "任务列表"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/upgrade-packages-mgr/grounding-strategies/:upgrade_package_id/batch-task",
                    "name": "batch-task",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteUpgrade__GroundingStrategies__batchTaskList' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteUpgrade/GroundingStrategies/batchTaskList'), loading: LoadingComponent}),
                    "meta": {
                      "name": "按筛选项任务列表"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr",
                "name": "peripheral-package-upgrade-mgr",
                "routes": [
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr/firmware-upgrade",
                    "name": "firmware-upgrade",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PeripheralPackageUpgradeMgr__FirmwareList__FirmwareUpgrade' */'/app/my-app/src/pages/OperationMaintenanceCenter/PeripheralPackageUpgradeMgr/FirmwareList/FirmwareUpgrade'), loading: LoadingComponent}),
                    "meta": {
                      "name": "外设固件升级"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr/firmware-upgrade/firmware-list/list/:targetType/:targetTypeName",
                    "name": "firmware-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PeripheralPackageUpgradeMgr__FirmwareList' */'/app/my-app/src/pages/OperationMaintenanceCenter/PeripheralPackageUpgradeMgr/FirmwareList'), loading: LoadingComponent}),
                    "meta": {
                      "name": "外设固件升级列表"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr/firmware-upgrade/firmware-list/firmware-package-create/:targetType",
                    "name": "firmware-package-create",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PeripheralPackageUpgradeMgr__FirmwareList__create' */'/app/my-app/src/pages/OperationMaintenanceCenter/PeripheralPackageUpgradeMgr/FirmwareList/create'), loading: LoadingComponent}),
                    "meta": {
                      "name": "新增固件包"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr/firmware-upgrade/firmware-list/firmware-package-edit/:id/:targetType",
                    "name": "firmware-package-edit",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PeripheralPackageUpgradeMgr__FirmwareList__edit' */'/app/my-app/src/pages/OperationMaintenanceCenter/PeripheralPackageUpgradeMgr/FirmwareList/edit'), loading: LoadingComponent}),
                    "meta": {
                      "name": "编辑固件包"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr/firmware-upgrade/firmware-list/test-list/:id/:targetType",
                    "name": "test-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PeripheralPackageUpgradeMgr__FirmwareList__TestList' */'/app/my-app/src/pages/OperationMaintenanceCenter/PeripheralPackageUpgradeMgr/FirmwareList/TestList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr/software-upgrade",
                    "name": "software-upgrade",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PeripheralPackageUpgradeMgr__SoftwareList__SoftwareUpgrade' */'/app/my-app/src/pages/OperationMaintenanceCenter/PeripheralPackageUpgradeMgr/SoftwareList/SoftwareUpgrade'), loading: LoadingComponent}),
                    "meta": {
                      "name": "外设软件升级"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr/software-upgrade/software-list/list/:targetType/:targetTypeName",
                    "name": "software-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PeripheralPackageUpgradeMgr__SoftwareList' */'/app/my-app/src/pages/OperationMaintenanceCenter/PeripheralPackageUpgradeMgr/SoftwareList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr/software-upgrade/software-list/software-package-create/:targetType",
                    "name": "software-package-create",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PeripheralPackageUpgradeMgr__SoftwareList__create' */'/app/my-app/src/pages/OperationMaintenanceCenter/PeripheralPackageUpgradeMgr/SoftwareList/create'), loading: LoadingComponent}),
                    "meta": {
                      "name": "新增软件包"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr/software-upgrade/software-list/software-package-edit/:id/:targetType",
                    "name": "software-package-edit",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PeripheralPackageUpgradeMgr__SoftwareList__edit' */'/app/my-app/src/pages/OperationMaintenanceCenter/PeripheralPackageUpgradeMgr/SoftwareList/edit'), loading: LoadingComponent}),
                    "meta": {
                      "name": "编辑软件包"
                    },
                    "exact": true
                  },
                  {
                    "path": "/operation-maintenance-center/remote-upgrade/peripheral-package-upgrade-mgr/software-upgrade/software-list/test-list/:id",
                    "name": "test-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PeripheralPackageUpgradeMgr__SoftwareList__TestList' */'/app/my-app/src/pages/OperationMaintenanceCenter/PeripheralPackageUpgradeMgr/SoftwareList/TestList'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/work-order",
            "routes": [
              {
                "path": "/operation-maintenance-center/work-order/work-order-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__WorkOrder__workOrderList' */'/app/my-app/src/pages/OperationMaintenanceCenter/WorkOrder/workOrderList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/work-order/obsolete-work-order-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__WorkOrder__obsoleteWorkOrderList' */'/app/my-app/src/pages/OperationMaintenanceCenter/WorkOrder/obsoleteWorkOrderList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/work-order/management-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__WorkOrder__managementList' */'/app/my-app/src/pages/OperationMaintenanceCenter/WorkOrder/managementList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/cloud-config",
            "icon": "property-safety",
            "name": "cloud-config",
            "routes": [
              {
                "path": "/operation-maintenance-center/cloud-config/product-list",
                "name": "product-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudConfig__productList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudConfig/productList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-config/product-list/product-alias-list",
                "name": "product-alias-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudConfig__productAliasList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudConfig/productAliasList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-config/server-config",
                "name": "server-config",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudConfig__serverConfig' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudConfig/serverConfig'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-config/server-config/server-hardware-config",
                "name": "server-config-server-hardware-config",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudConfig__serverHardwareConfig' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudConfig/serverHardwareConfig'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-config/app-config",
                "name": "app-config",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudConfig__appConfig' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudConfig/appConfig'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-config/app-config/app-config-demo",
                "name": "app-config-app-config-demo",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudConfig__appConfigDemo' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudConfig/appConfigDemo'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-config/app-config/app-config-language",
                "name": "app-config-app-config-language",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudConfig__appConfigLanguage' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudConfig/appConfigLanguage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-config/app-config/app-config-language/app-config-language-demo",
                "name": "app-config-language-demo",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudConfig__appConfigLanguageDemo' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudConfig/appConfigLanguageDemo'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-config/stb-config",
                "name": "stb-config",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudConfig__stbConfig' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudConfig/stbConfig'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-config/stb-config/stb-hardware-config",
                "name": "stb-config-stb-hardware-config",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudConfig__stbConfigDemo' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudConfig/stbConfigDemo'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/cloud-music-library",
            "icon": "property-safety",
            "name": "cloud-music-library",
            "routes": [
              {
                "path": "/operation-maintenance-center/cloud-music-library/souge-files-list",
                "name": "souge-files-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudMusicLibrary__sougeFilesList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudMusicLibrary/sougeFilesList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-music-library/singer-files-list",
                "name": "singer-files-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudMusicLibrary__singerFilesList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudMusicLibrary/singerFilesList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-music-library/singer-img-files-list",
                "name": "singer-img-files-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudMusicLibrary__singerImgFilesList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudMusicLibrary/singerImgFilesList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/cloud-data",
            "icon": "property-safety",
            "name": "cloud-data",
            "routes": [
              {
                "path": "/operation-maintenance-center/cloud-data/disk-abnormal-info",
                "name": "disk-abnormal-info",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudData__diskAbnormalInfo' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudData/diskAbnormalInfo'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/cloud-data/product-code-list",
                "name": "product-code-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__CloudData__productCodeList' */'/app/my-app/src/pages/OperationMaintenanceCenter/CloudData/productCodeList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/maintexnance-system",
            "name": "maintenance-system",
            "icon": "tool",
            "routes": [
              {
                "path": "/operation-maintenance-center/maintexnance-system/feedback",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__MaintexnanceSystem__Feedback' */'/app/my-app/src/pages/OperationMaintenanceCenter/MaintexnanceSystem/Feedback'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/package-upgrade",
            "icon": "property-safety",
            "name": "package-upgrade",
            "routes": [
              {
                "path": "/operation-maintenance-center/package-upgrade/verify-package",
                "name": "verify-package",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PackageUpgrade__verifyPackage' */'/app/my-app/src/pages/OperationMaintenanceCenter/PackageUpgrade/verifyPackage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/package-upgrade/pilot-package",
                "name": "pilot-package",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PackageUpgrade__pilotPackage' */'/app/my-app/src/pages/OperationMaintenanceCenter/PackageUpgrade/pilotPackage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/package-upgrade/shelves-package",
                "name": "shelves-package",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PackageUpgrade__shelvesPackage' */'/app/my-app/src/pages/OperationMaintenanceCenter/PackageUpgrade/shelvesPackage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/package-upgrade/verify-package/package-create",
                "name": "package-manage-package-create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PackageUpgrade__packageCreate' */'/app/my-app/src/pages/OperationMaintenanceCenter/PackageUpgrade/packageCreate'), loading: LoadingComponent}),
                "meta": {
                  "name": "新增软件包"
                },
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/package-upgrade/device-follower",
                "name": "device-follower",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__PackageUpgrade__deviceFollower' */'/app/my-app/src/pages/OperationMaintenanceCenter/PackageUpgrade/deviceFollower'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/remote-desktop",
            "routes": [
              {
                "path": "/operation-maintenance-center/remote-desktop/operation-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteDesktop__RemoteDesktopOperationLog' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteDesktop/RemoteDesktopOperationLog'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/remote-desktop/exception-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteDesktop__RemoteDesktopExceptionLog' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteDesktop/RemoteDesktopExceptionLog'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/remote-desktop/nps-info",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__RemoteDesktop__RemoteDesktopNpsInfo' */'/app/my-app/src/pages/OperationMaintenanceCenter/RemoteDesktop/RemoteDesktopNpsInfo'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/early-warning-config",
            "icon": "property-safety",
            "name": "early-warning-config",
            "routes": [
              {
                "path": "/operation-maintenance-center/early-warning-config/warning-level",
                "name": "warning-level",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__EarlyWarningConfig__WarningLevel' */'/app/my-app/src/pages/OperationMaintenanceCenter/EarlyWarningConfig/WarningLevel'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/early-warning-config/warning-type",
                "name": "warning-type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__EarlyWarningConfig__WarningType' */'/app/my-app/src/pages/OperationMaintenanceCenter/EarlyWarningConfig/WarningType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/early-warning-config/error-code",
                "name": "error-code",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__EarlyWarningConfig__ErrorCode' */'/app/my-app/src/pages/OperationMaintenanceCenter/EarlyWarningConfig/ErrorCode'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/used-stb-devices",
            "name": "used-stb-devices",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__UsedStbDevices' */'/app/my-app/src/pages/OperationMaintenanceCenter/UsedStbDevices'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/operation-maintenance-center/used-stb-devices/",
                "redirect": "/operation-maintenance-center/used-stb-devices/",
                "exact": true
              }
            ]
          },
          {
            "path": "/operation-maintenance-center/stb-health-monitor",
            "routes": [
              {
                "path": "/operation-maintenance-center/stb-health-monitor/stb-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__StbHealthMonitor__StbList' */'/app/my-app/src/pages/OperationMaintenanceCenter/StbHealthMonitor/StbList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/stb-health-monitor/net-agent-list",
                "meta": {
                  "name": "代理商统计列表"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__StbHealthMonitor__NetCount__NetAgentList' */'/app/my-app/src/pages/OperationMaintenanceCenter/StbHealthMonitor/NetCount/NetAgentList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/stb-health-monitor/net-agent-list/net-company-list/:agentId/:agentName/:agentLevel",
                "meta": {
                  "name": "场所统计列表"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__StbHealthMonitor__NetCount__NetCompanyList' */'/app/my-app/src/pages/OperationMaintenanceCenter/StbHealthMonitor/NetCount/NetCompanyList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/stb-health-monitor/net-agent-list/net-company-list/:agentId/:agentName/:agentLevel/net-company-hardware-model-list/:companyId/:companyCode/:companyName",
                "meta": {
                  "name": "场所型号统计列表"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__StbHealthMonitor__NetCount__NetCompanyHardwareModelList' */'/app/my-app/src/pages/OperationMaintenanceCenter/StbHealthMonitor/NetCount/NetCompanyHardwareModelList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/stb-health-monitor/net-agent-list/net-company-list/:agentId/:agentName/:agentLevel/net-company-hardware-model-list/:companyId/:companyCode/:companyName/net-company-stb-device-list/:hardwareModel",
                "meta": {
                  "name": "场所盒子列表"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__StbHealthMonitor__NetCount__NetCompanyStbDeviceList' */'/app/my-app/src/pages/OperationMaintenanceCenter/StbHealthMonitor/NetCount/NetCompanyStbDeviceList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/stb-health-monitor/home-agent-list",
                "meta": {
                  "name": "代理商统计列表"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__StbHealthMonitor__HomeCount__HomeAgentList' */'/app/my-app/src/pages/OperationMaintenanceCenter/StbHealthMonitor/HomeCount/HomeAgentList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/stb-health-monitor/home-agent-list/home-agent-hardware-model-list/:clientCode/:agentName",
                "meta": {
                  "name": "代理商型号统计列表"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__StbHealthMonitor__HomeCount__HomeAgentHardwareModelList' */'/app/my-app/src/pages/OperationMaintenanceCenter/StbHealthMonitor/HomeCount/HomeAgentHardwareModelList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/operation-maintenance-center/stb-health-monitor/home-agent-list/home-agent-hardware-model-list/:clientCode/:agentName/home-agent-stb-device-list/:hardwareModel",
                "meta": {
                  "name": "代理商盒子列表"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationMaintenanceCenter__StbHealthMonitor__HomeCount__HomeAgentStbDeviceList' */'/app/my-app/src/pages/OperationMaintenanceCenter/StbHealthMonitor/HomeCount/HomeAgentStbDeviceList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/business-center",
        "name": "business-center",
        "routes": [
          {
            "path": "/business-center/authorize-manage",
            "icon": "idcard",
            "name": "authorize-manage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__DeviceAuthorizationList' */'/app/my-app/src/pages/BusinessCenter/DeviceAuthorizationList'), loading: LoadingComponent}),
            "hideInBreadcrumb": true,
            "routes": [
              {
                "path": "/business-center/authorize-manage/",
                "redirect": "/business-center/authorize-manage/device-authorization-list",
                "exact": true
              },
              {
                "path": "/business-center/authorize-manage/device-authorization-list",
                "name": "device-authorization-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__DeviceAuthorizationList' */'/app/my-app/src/pages/BusinessCenter/DeviceAuthorizationList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/agent-manage",
            "name": "agent-manage",
            "routes": [
              {
                "path": "/business-center/agent-manage/",
                "redirect": "/business-center/agent-manage/role",
                "exact": true
              },
              {
                "path": "/business-center/agent-manage/role",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentManage' */'/app/my-app/src/pages/BusinessCenter/AgentManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/agent-manage/role/audit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentManage__Audit' */'/app/my-app/src/pages/BusinessCenter/AgentManage/Audit'), loading: LoadingComponent}),
                "meta": {
                  "name": "代理商审核"
                },
                "exact": true
              },
              {
                "path": "/business-center/agent-manage/company/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentManage__CompanyList' */'/app/my-app/src/pages/BusinessCenter/AgentManage/CompanyList'), loading: LoadingComponent}),
                "meta": {
                  "name": "场所列表"
                },
                "exact": true
              },
              {
                "path": "/business-center/agent-manage/personlists",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentManage__PersonLists' */'/app/my-app/src/pages/BusinessCenter/AgentManage/PersonLists'), loading: LoadingComponent}),
                "meta": {
                  "name": "人员列表"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/lamp-manage",
            "name": "lamp-manage",
            "routes": [
              {
                "path": "/business-center/lamp-manage/",
                "redirect": "/business-center/lamp-manage/role",
                "exact": true
              },
              {
                "path": "/business-center/lamp-manage/role",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__LampManage' */'/app/my-app/src/pages/BusinessCenter/LampManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/lamp-manage/role/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__LampManage__RoleList' */'/app/my-app/src/pages/BusinessCenter/LampManage/RoleList'), loading: LoadingComponent}),
                "meta": {
                  "name": "用户列表"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/vod-manage",
            "name": "vod-manage",
            "routes": [
              {
                "path": "/business-center/vod-manage/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__VodManage' */'/app/my-app/src/pages/BusinessCenter/VodManage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/intelligent-control-manage",
            "name": "intelligent-control-manage",
            "routes": [
              {
                "path": "/business-center/intelligent-control-manage/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__IntelligentControlManage' */'/app/my-app/src/pages/BusinessCenter/IntelligentControlManage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/stereo-manage",
            "name": "stereo-manage",
            "routes": [
              {
                "path": "/business-center/stereo-manage/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__StereoManage' */'/app/my-app/src/pages/BusinessCenter/StereoManage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/agent-company-manage",
            "name": "agent-company-manage",
            "routes": [
              {
                "path": "/business-center/agent-company-manage/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentCompanyManage' */'/app/my-app/src/pages/BusinessCenter/AgentCompanyManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/agent-company-manage/company-user-list",
                "name": "agent-company-manage-company-user-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentCompanyManage__companyUserList' */'/app/my-app/src/pages/BusinessCenter/AgentCompanyManage/companyUserList'), loading: LoadingComponent}),
                "meta": {
                  "name": "场所用户列表"
                },
                "exact": true
              },
              {
                "path": "/business-center/agent-company-manage/company-fengyun-devices",
                "name": "agent-company-manage-company-fengyun-devices",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentCompanyManage__companyFengyunDevices' */'/app/my-app/src/pages/BusinessCenter/AgentCompanyManage/companyFengyunDevices'), loading: LoadingComponent}),
                "meta": {
                  "name": "场所锋云列表"
                },
                "exact": true
              },
              {
                "path": "/business-center/agent-company-manage/company-stb-devices",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentCompanyManage__CompanySTBDevices' */'/app/my-app/src/pages/BusinessCenter/AgentCompanyManage/CompanySTBDevices'), loading: LoadingComponent}),
                "meta": {
                  "name": "机顶盒列表"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/chain-control",
            "routes": [
              {
                "path": "/business-center/chain-control/chain-brand-manage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__ChainCenter__Manage' */'/app/my-app/src/pages/BusinessCenter/ChainCenter/Manage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/chain-control/chain-brand-store-manage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__ChainCenter__ChainBrandStore' */'/app/my-app/src/pages/BusinessCenter/ChainCenter/ChainBrandStore'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/chain-control/chain-brand-store-manage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__ChainCenter__ChainBrandStore' */'/app/my-app/src/pages/BusinessCenter/ChainCenter/ChainBrandStore'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/chain-control/chain-brand-store-manage/create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__ChainCenter__ChainBrandStore__Create' */'/app/my-app/src/pages/BusinessCenter/ChainCenter/ChainBrandStore/Create'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/chain-control/chain-brand-store-manage/:chainBrandStoreId/edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__ChainCenter__ChainBrandStore__Edit' */'/app/my-app/src/pages/BusinessCenter/ChainCenter/ChainBrandStore/Edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/businessAuthorization",
            "icon": "property-safety",
            "name": "businessAuthorization",
            "routes": [
              {
                "path": "/business-center/businessAuthorization/",
                "redirect": "/business-center/businessAuthorization/agentauthorizationlist",
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/agentauthorizationlist",
                "name": "agentauthorizationlist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__AgentAuthorizationList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/AgentAuthorizationList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/agentauthorizationloglist",
                "name": "agentauthorizationloglist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__AgentAuthorizationLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/AgentAuthorizationLogList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/yt_order_list",
                "name": "yt_order_list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__YTOrderList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/YTOrderList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/soft_dog_info_list",
                "name": "soft_dog_info_list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__SoftDogInfoList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/SoftDogInfoList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/yt_agent_list",
                "name": "yt_agent_list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__YTAgentList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/YTAgentList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/agent_info_list",
                "name": "agent_info_list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__AgentInfoList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/AgentInfoList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/sb_agent_list",
                "name": "sb_agent_list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__SBAgentList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/SBAgentList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationloglist",
                "name": "companyauthorizationloglist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__CompanyAuthorizationLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/CompanyAuthorizationLogList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyexperimentauthorizationloglist",
                "name": "companyexperimentauthorizationloglist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__CompanyExperimentAuthorizationLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/CompanyExperimentAuthorizationLogList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist",
                "name": "companyauthorizationlist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__CompanyAuthorization' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/CompanyAuthorization'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/list",
                "name": "companyauthorizationlistList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__CompanyAuthorizationList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/CompanyAuthorizationList'), loading: LoadingComponent}),
                "meta": {
                  "name": "CDN支撑服务授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/zkcompanyauthorizationlist",
                "name": "zkcompanyauthorizationlist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__zkCompanyAuthorizationList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/zkCompanyAuthorizationList'), loading: LoadingComponent}),
                "meta": {
                  "name": "三方智控授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/zkcompanyauthorizationlist/zkcompanyauthorizationloglist",
                "name": "zkcompanyauthorizationloglist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__zkCompanyAuthorizationLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/zkCompanyAuthorizationLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "三方智控授权记录"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/zkcompanyauthorizationlist/zkactivatedevicelist",
                "name": "zkactivatedevicelist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__zkActivateDeviceList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/zkActivateDeviceList'), loading: LoadingComponent}),
                "meta": {
                  "name": "授权设备列表"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/zkcompanyauthorizationlist/zkcontrollist",
                "name": "zkcontrollist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__zkControlList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/zkControlList'), loading: LoadingComponent}),
                "meta": {
                  "name": "智控管控"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/zkcompanyauthorizationlist/zkcontrollist/zkauthorizationloglist",
                "name": "zkauthorizationloglist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__zkAuthorizationLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/zkAuthorizationLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "智控授权请求记录"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/vocal-auth-list",
                "name": "vocal-auth-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__vocalAuthList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/vocalAuthList'), loading: LoadingComponent}),
                "meta": {
                  "name": "语音收费授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/vocal-auth-list/vocal-auth-log-list",
                "name": "vocal-auth-log-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__vocalAuthLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/vocalAuthLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "授权记录"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/vocal-auth-list/company-vocal-auth-config-list",
                "name": "company-vocal-auth-config-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__companyVocalAuthConfigList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/companyVocalAuthConfigList'), loading: LoadingComponent}),
                "meta": {
                  "name": "商家授权配置"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/vocal-auth-list/vocal-auth-transfer-log",
                "name": "vocal-auth-transfer-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__vocalAuthTranferLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/vocalAuthTranferLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "授权转移记录"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/vocal-auth-list/vocal-auth-config-list",
                "name": "vocal-auth-config-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__vocalAuthConfigList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/vocalAuthConfigList'), loading: LoadingComponent}),
                "meta": {
                  "name": "授权配置"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/splicing-screen-auth",
                "name": "splicing-screen-auth",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__splicingScreenAuthList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/splicingScreenAuthList'), loading: LoadingComponent}),
                "meta": {
                  "name": "拼接屏授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/splicing-screen-auth/splicing-screen-auth-log",
                "name": "splicing-screen-auth-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__splicingScreenAuthLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/splicingScreenAuthLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "授权记录"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/splicing-screen-auth/splicing-screen-auth-transfer-log",
                "name": "splicing-screen-auth-transfer-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__splicingScreenAuthTransferLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/splicingScreenAuthTransferLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "授权转移记录"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/stb-auth",
                "name": "stb-auth",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__StbAuth' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/StbAuth'), loading: LoadingComponent}),
                "meta": {
                  "name": "机顶盒CDW授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/themeResourceServicesAuthorizationList",
                "name": "themeResourceServicesAuthorizationList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__ThemeResourceServicesAuthorizationList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/ThemeResourceServicesAuthorizationList'), loading: LoadingComponent}),
                "meta": {
                  "name": "主题资源服务授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/fourkServicesAuthorizationList",
                "name": "fourkServicesAuthorizationList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__FourkServicesAuthorizationList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/FourkServicesAuthorizationList'), loading: LoadingComponent}),
                "meta": {
                  "name": "超清服务授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/scanCodeAuthorizationList",
                "name": "scanCodeAuthorizationList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__ScanCodeAuthorizationList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/ScanCodeAuthorizationList'), loading: LoadingComponent}),
                "meta": {
                  "name": "扫码开机授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/scanCodeAuthorizationList/scanCodeAuthorizationLogList",
                "name": "scanCodeAuthorizationLogList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__ScanCodeAuthorizationList__AuthLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/ScanCodeAuthorizationList/AuthLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "扫码开机授权记录"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/screen-projection-auth",
                "name": "screen-projection-auth",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__screenProjectionAuthList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/screenProjectionAuthList'), loading: LoadingComponent}),
                "meta": {
                  "name": "投屏授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/screen-projection-auth/screen-projection-auth-log",
                "name": "screen-projection-auth-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__screenProjectionAuthLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/screenProjectionAuthLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "授权记录"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/screen-projection-auth/screen-projection-auth-transfer-log",
                "name": "screen-projection-auth-transfer-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__screenProjectionAuthTransferLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/screenProjectionAuthTransferLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "授权转移记录"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/aiauthorizationlist",
                "name": "aiauthorizationlist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__AIAuthorizationList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/AIAuthorizationList'), loading: LoadingComponent}),
                "meta": {
                  "name": "AI授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/small-commodity-auth",
                "name": "small-commodity-auth",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__smallCommodityAuthList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/smallCommodityAuthList'), loading: LoadingComponent}),
                "meta": {
                  "name": "钻石授权"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/small-commodity-auth/small-commodity-auth-log",
                "name": "small-commodity-auth-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__smallCommodityAuthLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/smallCommodityAuthLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "钻石授权记录"
                },
                "exact": true
              },
              {
                "path": "/business-center/businessAuthorization/companyauthorizationlist/small-commodity-auth/small-commodity-auth-transfer-log",
                "name": "small-commodity-auth-transfer-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BusinessAuthorization__smallCommodityAuthTransferLogList' */'/app/my-app/src/pages/ValueAddedCenter/BusinessAuthorization/smallCommodityAuthTransferLogList'), loading: LoadingComponent}),
                "meta": {
                  "name": "钻石授权转移记录"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/customer-manage",
            "name": "customer-manage",
            "routes": [
              {
                "path": "/business-center/customer-manage/",
                "redirect": "/business-center/customer-manage/customer-list",
                "exact": true
              },
              {
                "path": "/business-center/customer-manage/customer-list",
                "name": "customer-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__CustomerManage' */'/app/my-app/src/pages/BusinessCenter/CustomerManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/customer-manage/customer-list/company-user-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentCompanyManage__companyUserList' */'/app/my-app/src/pages/BusinessCenter/AgentCompanyManage/companyUserList'), loading: LoadingComponent}),
                "meta": {
                  "name": "场所用户列表"
                },
                "exact": true
              },
              {
                "path": "/business-center/customer-manage/customer-examine",
                "name": "customer-examine",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__CustomerManage__CustomerExamine' */'/app/my-app/src/pages/BusinessCenter/CustomerManage/CustomerExamine'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/push-delete-song-log",
            "name": "push-delete-song-log",
            "routes": [
              {
                "path": "/business-center/push-delete-song-log/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__PushDeleteSongLog' */'/app/my-app/src/pages/BusinessCenter/PushDeleteSongLog'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/voice-song-mgr",
            "name": "./voice-song-mgr",
            "routes": [
              {
                "path": "/business-center/voice-song-mgr/",
                "redirect": "/business-center/voice-song-mgr/voice-song-statistics",
                "exact": true
              },
              {
                "path": "/business-center/voice-song-mgr/voice-song-statistics",
                "name": "voice-song-statistics",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__VoiceSongMgr__VoiceSongStatistics__index' */'/app/my-app/src/pages/BusinessCenter/VoiceSongMgr/VoiceSongStatistics/index.jsx'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/company-bind-cdn",
            "routes": [
              {
                "path": "/business-center/company-bind-cdn/apply-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__CompanyBindCDN__ApplyList' */'/app/my-app/src/pages/BusinessCenter/CompanyBindCDN/ApplyList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/company-bind-cdn/binding-relationship-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__CompanyBindCDN__BindingRelationshipList' */'/app/my-app/src/pages/BusinessCenter/CompanyBindCDN/BindingRelationshipList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/company-bind-cdn/cdn-suspected-merchant",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__CompanyBindCDN__CDNSuspectedMerchant' */'/app/my-app/src/pages/BusinessCenter/CompanyBindCDN/CDNSuspectedMerchant'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/company-bind-cdn/multiple-fengyun-auth-devices-company-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__CompanyBindCDN__multipleFengyunAuthDevicesCompanyList' */'/app/my-app/src/pages/BusinessCenter/CompanyBindCDN/multipleFengyunAuthDevicesCompanyList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/company-bind-cdn/stb-bind-auth-fengyun-device-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__CompanyBindCDN__stbBindAuthFengyunDeviceList' */'/app/my-app/src/pages/BusinessCenter/CompanyBindCDN/stbBindAuthFengyunDeviceList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/company-bind-cdn/stb-bind-unlock-audit-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__CompanyBindCDN__stbBindUnlockAuditList' */'/app/my-app/src/pages/BusinessCenter/CompanyBindCDN/stbBindUnlockAuditList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/phone-cdn",
            "name": "phone-cdn",
            "routes": [
              {
                "path": "/business-center/phone-cdn/",
                "redirect": "/business-center/phone-cdn/month-calculations",
                "exact": true
              },
              {
                "path": "/business-center/phone-cdn/month-calculations",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__PhoneCDNMonthCalculations' */'/app/my-app/src/pages/BusinessCenter/PhoneCDNMonthCalculations'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/phone-cdn/domain",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__PhoneCDNDomain' */'/app/my-app/src/pages/BusinessCenter/PhoneCDNDomain'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/tenant-manage",
            "name": "tenant-manage",
            "routes": [
              {
                "path": "/business-center/tenant-manage/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__TenantManage' */'/app/my-app/src/pages/BusinessCenter/TenantManage'), loading: LoadingComponent}),
                "meta": {
                  "name": "厂商管理"
                },
                "exact": true
              },
              {
                "path": "/business-center/tenant-manage/create-tenant",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__TenantManage__CreateTenant' */'/app/my-app/src/pages/BusinessCenter/TenantManage/CreateTenant'), loading: LoadingComponent}),
                "meta": {
                  "name": "创建厂商"
                },
                "exact": true
              },
              {
                "path": "/business-center/tenant-manage/update-tenant/:tenantId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__TenantManage__UpdateTenant' */'/app/my-app/src/pages/BusinessCenter/TenantManage/UpdateTenant'), loading: LoadingComponent}),
                "meta": {
                  "name": "编辑厂商"
                },
                "exact": true
              },
              {
                "path": "/business-center/tenant-manage/tenant-user-list/:tenantId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__TenantManage__UserList' */'/app/my-app/src/pages/BusinessCenter/TenantManage/UserList'), loading: LoadingComponent}),
                "meta": {
                  "name": "厂商人员列表"
                },
                "exact": true
              },
              {
                "path": "/business-center/tenant-manage/tenant-agent-list/:tenantId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__TenantManage__AgentList' */'/app/my-app/src/pages/BusinessCenter/TenantManage/AgentList'), loading: LoadingComponent}),
                "meta": {
                  "name": "厂商代理商列表"
                },
                "exact": true
              },
              {
                "path": "/business-center/tenant-manage/tenant-device-hardware-model-list/:tenantId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__TenantManage__DeviceHardwareModelList' */'/app/my-app/src/pages/BusinessCenter/TenantManage/DeviceHardwareModelList'), loading: LoadingComponent}),
                "meta": {
                  "name": "厂商设备型号列表"
                },
                "exact": true
              },
              {
                "path": "/business-center/tenant-manage/tenant-menu-template-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__TenantManage__MenuTemplateList' */'/app/my-app/src/pages/BusinessCenter/TenantManage/MenuTemplateList'), loading: LoadingComponent}),
                "meta": {
                  "name": "厂商菜单模板列表"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/home-manage",
            "routes": [
              {
                "path": "/business-center/home-manage/",
                "redirect": "/business-center/home-manage/agent-manage",
                "exact": true
              },
              {
                "path": "/business-center/home-manage/agent-manage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentManage' */'/app/my-app/src/pages/BusinessCenter/AgentManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/home-manage/agent-manage/audit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentManage__Audit' */'/app/my-app/src/pages/BusinessCenter/AgentManage/Audit'), loading: LoadingComponent}),
                "meta": {
                  "name": "代理商审核"
                },
                "exact": true
              },
              {
                "path": "/business-center/home-manage/customer-manage",
                "routes": [
                  {
                    "path": "/business-center/home-manage/customer-manage/",
                    "meta": {
                      "name": "客户列表"
                    },
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__HomeManage__CustomerManage' */'/app/my-app/src/pages/BusinessCenter/HomeManage/CustomerManage'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/business-center/home-manage/customer-manage/stb-list/:customerCode/:customerName",
                    "meta": {
                      "name": "盒子列表"
                    },
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__HomeManage__CustomerManage__StbList' */'/app/my-app/src/pages/BusinessCenter/HomeManage/CustomerManage/StbList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/business-center/home-manage/customer-manage/customer-scan-auth-log-list/:customer_code/:customer_name",
                    "meta": {
                      "name": "扫码开机授权记录"
                    },
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__HomeManage__CustomerManage__AuthLogList' */'/app/my-app/src/pages/BusinessCenter/HomeManage/CustomerManage/AuthLogList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/business-center/home-manage/customer-manage/company-user-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__AgentCompanyManage__companyUserList' */'/app/my-app/src/pages/BusinessCenter/AgentCompanyManage/companyUserList'), loading: LoadingComponent}),
                    "meta": {
                      "name": "场所用户列表"
                    },
                    "exact": true
                  },
                  {
                    "path": "/business-center/home-manage/customer-manage/small-commercial-type-manage",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__HomeManage__CustomerManage__SmallCommercialType' */'/app/my-app/src/pages/BusinessCenter/HomeManage/CustomerManage/SmallCommercialType'), loading: LoadingComponent}),
                    "meta": {
                      "name": "商用单机经营类型管理"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/business-center/home-manage/customer-examine",
                "meta": {
                  "name": "客户审核列表"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__CustomerManage__CustomerExamine' */'/app/my-app/src/pages/BusinessCenter/CustomerManage/CustomerExamine'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/home-manage/stb-manage",
                "meta": {
                  "name": "机顶盒列表"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__HomeManage__StbManage' */'/app/my-app/src/pages/BusinessCenter/HomeManage/StbManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/home-manage/disable-microservice",
                "meta": {
                  "name": "禁用微服务"
                },
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__HomeManage__DisableMicroservice' */'/app/my-app/src/pages/BusinessCenter/HomeManage/DisableMicroservice'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/business-center/lamp-factory-mgr",
            "name": "lamp-factory-mgr",
            "routes": [
              {
                "path": "/business-center/lamp-factory-mgr/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__LampFactoryMgr' */'/app/my-app/src/pages/BusinessCenter/LampFactoryMgr'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/business-center/lamp-factory-mgr/create-tenant",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__TenantManage__CreateTenant' */'/app/my-app/src/pages/BusinessCenter/TenantManage/CreateTenant'), loading: LoadingComponent}),
                "meta": {
                  "name": "创建厂商"
                },
                "exact": true
              },
              {
                "path": "/business-center/lamp-factory-mgr/update-tenant/:tenantId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__TenantManage__UpdateTenant' */'/app/my-app/src/pages/BusinessCenter/TenantManage/UpdateTenant'), loading: LoadingComponent}),
                "meta": {
                  "name": "编辑厂商"
                },
                "exact": true
              },
              {
                "path": "/business-center/lamp-factory-mgr/lamp-list/:lampFactoryId/:lampFactoryName",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__LampFactoryMgr__LampDeviceList' */'/app/my-app/src/pages/BusinessCenter/LampFactoryMgr/LampDeviceList'), loading: LoadingComponent}),
                "meta": {
                  "name": "${lampFactoryName}"
                },
                "exact": true
              },
              {
                "path": "/business-center/lamp-factory-mgr/lamp-channel-list/:lampFactoryName/:lampModelName/:lampId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BusinessCenter__LampFactoryMgr__LampChannelList' */'/app/my-app/src/pages/BusinessCenter/LampFactoryMgr/LampChannelList'), loading: LoadingComponent}),
                "meta": {
                  "name": "${lampFactoryName} > ${lampModelName} > 通道数列表"
                },
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/monitor-center",
        "name": "monitor-center",
        "isTop": true,
        "routes": [
          {
            "path": "/monitor-center/bugsell-monitor",
            "name": "bugsell-monitor",
            "icon": "shake",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__empty' */'/app/my-app/src/pages/Exception/empty'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/monitor-center/company-devices-monitor",
            "name": "company-devices-monitor",
            "icon": "cluster",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__empty' */'/app/my-app/src/pages/Exception/empty'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/monitor-center/early-warning-center",
            "name": "early-warning-center",
            "icon": "alert",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__empty' */'/app/my-app/src/pages/Exception/empty'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/monitor-center/piracy",
            "name": "piracy",
            "routes": [
              {
                "path": "/monitor-center/piracy/",
                "redirect": "/monitor-center/piracy/fengyun-piracy",
                "exact": true
              },
              {
                "path": "/monitor-center/piracy/fengyun-piracy",
                "name": "fengyun-piracy",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MonitorCenter__Piracy__FengyunPiracy' */'/app/my-app/src/pages/MonitorCenter/Piracy/FengyunPiracy'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/monitor-center/piracy/system-piracy",
                "name": "system-piracy",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MonitorCenter__Piracy__SystemPiracy' */'/app/my-app/src/pages/MonitorCenter/Piracy/SystemPiracy'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/monitor-center/punish",
            "name": "punish",
            "routes": [
              {
                "path": "/monitor-center/punish/",
                "redirect": "/monitor-center/punish/schemes",
                "exact": true
              },
              {
                "path": "/monitor-center/punish/schemes",
                "name": "schemes",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MonitorCenter__Punish__Schemes__SchemesList' */'/app/my-app/src/pages/MonitorCenter/Punish/Schemes/SchemesList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/monitor-center/punish/company",
                "name": "punish-company",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MonitorCenter__Punish__Company__CompanyList' */'/app/my-app/src/pages/MonitorCenter/Punish/Company/CompanyList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/monitor-center/punish/company/:company_id",
                "routes": [
                  {
                    "path": "/monitor-center/punish/company/:company_id/detail",
                    "name": "detail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MonitorCenter__Punish__Company__Detail' */'/app/my-app/src/pages/MonitorCenter/Punish/Company/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "path": "/support-center",
        "name": "support-center",
        "isTop": true,
        "routes": []
      },
      {
        "path": "/value-added-center",
        "name": "value-added-center",
        "routes": [
          {
            "path": "/value-added-center/intelligent-control-center",
            "name": "intelligent-control-center",
            "routes": [
              {
                "path": "/value-added-center/intelligent-control-center/intelligent-control-market/:pageType",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__IntelligentControlMarket' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/IntelligentControlMarket'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/value-added-center/intelligent-control-center/intelligent-control-market/:pageType/",
                    "redirect": "/value-added-center/intelligent-control-center/intelligent-control-market/:pageType/lamp-warehouse",
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/intelligent-control-market/:pageType/lamp-warehouse",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__IntelligentControlMarket__LampWarehouse' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/IntelligentControlMarket/LampWarehouse'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/intelligent-control-market/:pageType/lamp-info",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__IntelligentControlMarket__LampInfo' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/IntelligentControlMarket/LampInfo'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/intelligent-control-market/:pageType/lamp-effect",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__IntelligentControlMarket__LampEffect' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/IntelligentControlMarket/LampEffect'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/intelligent-control-center/agents",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampAgents' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampAgents'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/value-added-center/intelligent-control-center/agents/",
                    "redirect": "/value-added-center/intelligent-control-center/agents/lamp-warehouse",
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/agents/lamp-warehouse",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampAgents__LampWarehouse' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampAgents/LampWarehouse'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/agents/lamp-effect",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampAgents__LampEffect' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampAgents/LampEffect'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/intelligent-control-center/manufacturer",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampManufacturer' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampManufacturer'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/value-added-center/intelligent-control-center/manufacturer/",
                    "redirect": "/value-added-center/intelligent-control-center/manufacturer/lamp-warehouse",
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/manufacturer/lamp-warehouse",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampManufacturer__LampWarehouse' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampManufacturer/LampWarehouse'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/manufacturer/lamp-info",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampManufacturer__LampInfo' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampManufacturer/LampInfo'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/manufacturer/lamp-effect",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampManufacturer__LampEffect' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampManufacturer/LampEffect'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/intelligent-control-center/review",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampReview' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampReview'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/value-added-center/intelligent-control-center/review/",
                    "redirect": "/value-added-center/intelligent-control-center/review/lamp-warehouse",
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/review/lamp-info",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampReview__LampInfo' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampReview/LampInfo'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/review/lamp-effect",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampReview__LampEffect' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampReview/LampEffect'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/intelligent-control-center/review/lamp-warehouse",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LampReview__LampWarehouse' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LampReview/LampWarehouse'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/intelligent-control-center/light-code",
                "name": "light-code",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LightCode' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LightCode'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/intelligent-control-center/light-brand",
                "name": "light-brand",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LightBrand' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LightBrand'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/value-added-center/nightclub-skin",
            "name": "nightclub-skin",
            "icon": "skin",
            "routes": [
              {
                "path": "/value-added-center/nightclub-skin/",
                "redirect": "/value-added-center/nightclub-skin/list",
                "exact": true
              },
              {
                "path": "/value-added-center/nightclub-skin/list",
                "name": "list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__NightclubSkin__List' */'/app/my-app/src/pages/ValueAddedCenter/NightclubSkin/List'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/nightclub-skin/list/create",
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__NightclubSkin__Create' */'/app/my-app/src/pages/ValueAddedCenter/NightclubSkin/Create'), loading: LoadingComponent}),
                "meta": {
                  "name": "创建皮肤"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/nightclub-skin/list/:skin/edit",
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__NightclubSkin__Edit' */'/app/my-app/src/pages/ValueAddedCenter/NightclubSkin/Edit'), loading: LoadingComponent}),
                "meta": {
                  "name": "编辑皮肤"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/value-added-center/net-chorus-fee",
            "routes": [
              {
                "path": "/value-added-center/net-chorus-fee/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__NetChorusFee' */'/app/my-app/src/pages/ValueAddedCenter/NetChorusFee'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/net-chorus-fee/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__NetChorusFee__ChannelLogs' */'/app/my-app/src/pages/ValueAddedCenter/NetChorusFee/ChannelLogs'), loading: LoadingComponent}),
                "meta": {
                  "name": "场次详情"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/value-added-center/companies",
            "routes": [
              {
                "path": "/value-added-center/companies/",
                "name": "companies",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Companies' */'/app/my-app/src/pages/ValueAddedCenter/Companies'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/companies/company/:companyId/skins",
                "name": "company-skin",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Companies__CompanySkin' */'/app/my-app/src/pages/ValueAddedCenter/Companies/CompanySkin'), loading: LoadingComponent}),
                "meta": {
                  "name": "皮肤推送"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/companies/company/:companyId/pad-advert",
                "name": "company-skin",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Companies__PadAdvertSetting' */'/app/my-app/src/pages/ValueAddedCenter/Companies/PadAdvertSetting'), loading: LoadingComponent}),
                "meta": {
                  "name": "场所PAD广告方案"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId",
            "name": "materialMarket",
            "routes": [
              {
                "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z70Market",
                "name": "z70Market",
                "routes": [
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z70Market/company_rsbox",
                    "name": "company_rsbox",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Z70Market__CompanyRsbox' */'/app/my-app/src/pages/ValueAddedCenter/Z70Market/CompanyRsbox'), loading: LoadingComponent}),
                    "meta": {
                      "name": "Z系列"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z70Market/company_rsbox/company_resource_usable",
                    "name": "company_resource_usable",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Z70Market__CompanyResourceUsable' */'/app/my-app/src/pages/ValueAddedCenter/Z70Market/CompanyResourceUsable'), loading: LoadingComponent}),
                    "meta": {
                      "name": "场所可用素材"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z70Market/company_rsbox/:mac/resource_buy/:companyId",
                    "name": "resource_buy",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Z70Market__CompanyResMgr' */'/app/my-app/src/pages/ValueAddedCenter/Z70Market/CompanyResMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "素材管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z70Market/company_rsbox/:mac/resource_buy_tasks",
                    "name": "resource_buy_tasks",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Z70Market__ResourceBuyTasks' */'/app/my-app/src/pages/ValueAddedCenter/Z70Market/ResourceBuyTasks'), loading: LoadingComponent}),
                    "meta": {
                      "name": "购买记录"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z66Market",
                "name": "z66Market",
                "routes": [
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z66Market/company_rsbox",
                    "name": "company_rsbox",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Z66Market__CompanyRsbox' */'/app/my-app/src/pages/ValueAddedCenter/Z66Market/CompanyRsbox'), loading: LoadingComponent}),
                    "meta": {
                      "name": "壁画"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z66Market/company_rsbox/company_resource_usable",
                    "name": "company_resource_usable",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Z66Market__CompanyResourceUsable' */'/app/my-app/src/pages/ValueAddedCenter/Z66Market/CompanyResourceUsable'), loading: LoadingComponent}),
                    "meta": {
                      "name": "场所可用素材"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z66Market/company_rsbox/:mac/resource_buy/:companyId",
                    "name": "resource_buy",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Z66Market__CompanyResMgr' */'/app/my-app/src/pages/ValueAddedCenter/Z66Market/CompanyResMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "素材管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z66Market/company_rsbox/:mac/boxs_resource_buy/:companyId",
                    "name": "boxs_resource_buy",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Z66Market__CompanyBoxsResMgr' */'/app/my-app/src/pages/ValueAddedCenter/Z66Market/CompanyBoxsResMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "素材管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/z66Market/company_rsbox/:mac/resource_buy_tasks",
                    "name": "resource_buy_tasks",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__Z66Market__ResourceBuyTasks' */'/app/my-app/src/pages/ValueAddedCenter/Z66Market/ResourceBuyTasks'), loading: LoadingComponent}),
                    "meta": {
                      "name": "购买记录"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/highMarket",
                "name": "highMarket",
                "routes": [
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/highMarket/company_rsbox",
                    "name": "company_rsbox",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RHMarket__CompanyRsbox' */'/app/my-app/src/pages/ValueAddedCenter/RHMarket/CompanyRsbox'), loading: LoadingComponent}),
                    "meta": {
                      "name": "VJ服务器"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/highMarket/company_rsbox/:mac/resource_mgr/:companyId",
                    "name": "resource_mgr",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RHMarket__CompanyResMgr' */'/app/my-app/src/pages/ValueAddedCenter/RHMarket/CompanyResMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "素材管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/highMarket/company_rsbox/:mac/resource_buy_tasks",
                    "name": "resource_buy_tasks",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RHMarket__CompanyResource__ResourceBuyTasks' */'/app/my-app/src/pages/ValueAddedCenter/RHMarket/CompanyResource/ResourceBuyTasks'), loading: LoadingComponent}),
                    "meta": {
                      "name": "购买记录"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/highMarket/company_rsbox/company_resource_usable",
                    "name": "company_resource_usable",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RHMarket__CompanyResource__CompanyResourceUsable' */'/app/my-app/src/pages/ValueAddedCenter/RHMarket/CompanyResource/CompanyResourceUsable'), loading: LoadingComponent}),
                    "meta": {
                      "name": "场所可用素材"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/highMarket/company_rsbox/:mac/materialTypeRelationSongType",
                    "name": "materialTypeRelationSongType",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RHMarket__CompanyResource__MaterialTypeRelationSongType' */'/app/my-app/src/pages/ValueAddedCenter/RHMarket/CompanyResource/MaterialTypeRelationSongType'), loading: LoadingComponent}),
                    "meta": {
                      "name": "歌曲类型关联素材类型"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/highMarket/company_rsbox/:mac/materialRelationSong",
                    "name": "materialRelationSong",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RHMarket__CompanyResource__MaterialRelationSong' */'/app/my-app/src/pages/ValueAddedCenter/RHMarket/CompanyResource/MaterialRelationSong'), loading: LoadingComponent}),
                    "meta": {
                      "name": "歌曲关联素材"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/fmMarket",
                "name": "fmMarket",
                "routes": [
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/fmMarket/company_rsbox",
                    "name": "shop_fmbox",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__FMMarket__ShopList__ShopFMbox__ShopFMbox' */'/app/my-app/src/pages/ValueAddedCenter/FMMarket/ShopList/ShopFMbox/ShopFMbox'), loading: LoadingComponent}),
                    "meta": {
                      "name": "Z系列（Z50、Z55、Z60）"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/itMarket",
                "name": "itMarket",
                "routes": [
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/itMarket/company_rsbox",
                    "name": "company_rsbox",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ITMarket__CompanyResource__CompanyRsbox' */'/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/CompanyRsbox'), loading: LoadingComponent}),
                    "meta": {
                      "name": "互动盒子"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/itMarket/company_rsbox/:mac/resource_buy/:customerCode",
                    "name": "resource_buy",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ITMarket__CompanyResource__ResourceBuy' */'/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/ResourceBuy'), loading: LoadingComponent}),
                    "meta": {
                      "name": "购买素材"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/itMarket/company_rsbox/:mac/resource_usable",
                    "name": "resource_usable",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ITMarket__CompanyResource__ResourceUsable' */'/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/ResourceUsable'), loading: LoadingComponent}),
                    "meta": {
                      "name": "可用素材"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/itMarket/company_rsbox/:mac/resource_buy_tasks",
                    "name": "resource_buy_tasks",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ITMarket__CompanyResource__ResourceBuyTasks' */'/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/ResourceBuyTasks'), loading: LoadingComponent}),
                    "meta": {
                      "name": "购买记录"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/itMarket/company_rsbox/company_resource_usable",
                    "name": "company_resource_usable",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ITMarket__CompanyResource__CompanyResourceUsable' */'/app/my-app/src/pages/ValueAddedCenter/ITMarket/CompanyResource/CompanyResourceUsable'), loading: LoadingComponent}),
                    "meta": {
                      "name": "场所可用素材"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/",
                "name": "rsMarket",
                "routes": [
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/company_rsbox",
                    "name": "company_rsbox",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__CompanyRsbox' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/CompanyRsbox'), loading: LoadingComponent}),
                    "meta": {
                      "name": "R系列"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/company_rsbox/:mac/resource_buy/:companyId",
                    "name": "resource_buy",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__ResourceBuy' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/ResourceBuy'), loading: LoadingComponent}),
                    "meta": {
                      "name": "购买素材"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/company_rsbox/company_resource_usable",
                    "name": "company_resource_usable",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__CompanyResourceUsable' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/CompanyResourceUsable'), loading: LoadingComponent}),
                    "meta": {
                      "name": "场所可用素材"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/company_rsbox/:mac/resource_usable",
                    "name": "resource_usable",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__ResourceUsable' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/ResourceUsable'), loading: LoadingComponent}),
                    "meta": {
                      "name": "可用素材"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/company_rsbox/:mac/resource_buy_tasks",
                    "name": "resource_buy_tasks",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__ResourceBuyTasks' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/ResourceBuyTasks'), loading: LoadingComponent}),
                    "meta": {
                      "name": "购买记录"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/resource_push_tasks",
                    "name": "resource_push_tasks",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__ResourcePushTasks' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/ResourcePushTasks'), loading: LoadingComponent}),
                    "meta": {
                      "name": "推送记录"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/company_rsbox/:mac/resource_usable/:mac/c_rs_resourcesongtype/:sceneId",
                    "name": "c_rs_resourcesongtype",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__CRSResourceSongType' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/CRSResourceSongType'), loading: LoadingComponent}),
                    "meta": {
                      "name": "歌曲类型配置"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/company_rsbox/:mac/resource_usable/:mac/c_rs_resourcesong/:sceneCode",
                    "name": "c_rs_resourcesong",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__CRSResourceSong' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/CRSResourceSong'), loading: LoadingComponent}),
                    "meta": {
                      "name": "歌曲配置"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode/:companyId/company_rsbox/:mac/resource_usable/:mac/c_rs_resourcetailor/:sceneCode",
                    "name": "c_rs_resourcetailor",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__CRSResourceTailor' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/CRSResourceTailor'), loading: LoadingComponent}),
                    "meta": {
                      "name": "私人订制配置"
                    },
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "path": "/value-added-center/rsMarket/materialmanag",
            "name": "materialmanag",
            "routes": [
              {
                "path": "/value-added-center/rsMarket/materialmanag/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/high_scene_type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialTypeMgr__HighSceneType' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialTypeMgr/HighSceneType'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/high_scene_type/",
                    "redirect": "/value-added-center/rsMarket/materialmanag/high_scene_type/scene-type",
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/high_scene_type/scene-type",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialTypeMgr__SceneType' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialTypeMgr/SceneType'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/high_scene_type/scene-file-type",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialTypeMgr__SceneFileType' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialTypeMgr/SceneFileType'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/high_scene_type/scene-one-label",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialTypeMgr__SceneOneLabel' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialTypeMgr/SceneOneLabel'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/high_scene_type/scene-two-label",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialTypeMgr__SceneTwoLabel' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialTypeMgr/SceneTwoLabel'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/create",
                "name": "materialmanag.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialManag__MaterialManageCreate' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialManag/MaterialManageCreate'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/edit/:id",
                "name": "materialmanag.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialManag__MaterialManageEdit' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialManag/MaterialManageEdit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialhigh/create",
                "name": "materialhigh.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialHigh__MaterialHighCreate' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialHigh/MaterialHighCreate'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialhigh/batch_create",
                "name": "materialhigh.batch_create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialHigh__BatchAddMaterial' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialHigh/BatchAddMaterial'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialhigh/materialTypeRelationSongType",
                "name": "materialhigh.materialTypeRelationSongType",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialHigh__MaterialTypeRelationSongType' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialHigh/MaterialTypeRelationSongType'), loading: LoadingComponent}),
                "meta": {
                  "name": "歌曲类型关联素材类型"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialhigh/materialRelationSong",
                "name": "materialhigh.materialRelationSong",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialHigh__MaterialRelationSong' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialHigh/MaterialRelationSong'), loading: LoadingComponent}),
                "meta": {
                  "name": "歌曲关联素材"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialhigh/edit/:id",
                "name": "materialhigh.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialHigh__MaterialHighEdit' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialHigh/MaterialHighEdit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialhigh/resourceMandatoryVersionMgr",
                "name": "materialhigh.resourceMandatoryVersionMgr",
                "routes": [
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/materialhigh/resourceMandatoryVersionMgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialHigh__ResourceMandatoryVersionMgr' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialHigh/ResourceMandatoryVersionMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "必选素材版本管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/materialhigh/resourceMandatoryVersionMgr/resourceMandatoryListList/:id",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialHigh__ResourceMandatoryList' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialHigh/ResourceMandatoryList'), loading: LoadingComponent}),
                    "meta": {
                      "name": "素材必选列表"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialiteract/create",
                "name": "materialiteract.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialIteract__MaterialIteractCreate' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialIteract/MaterialIteractCreate'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialiteract/edit/:id",
                "name": "materialiteract.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialIteract__MaterialIteractEdit' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialIteract/MaterialIteractEdit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/rs-resourcesongtype",
                "name": "rs-resourcesongtype",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__RSResourceSongType' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/RSResourceSongType'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/rs-resourcesong",
                "name": "rs-resourcesong",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__RSResourceSong' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/RSResourceSong'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/rs-resourcetailor",
                "name": "rs-resourcetailor",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__RSResourceTailor' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/RSResourceTailor'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialZ66/create",
                "name": "materialZ66.create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialZ66__MaterialCreate' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialZ66/MaterialCreate'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialZ66/batch_create",
                "name": "materialZ66.batch_create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialZ66__BatchAddMaterial' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialZ66/BatchAddMaterial'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialZ66/batch_create_group",
                "name": "materialZ66.batch_create_group",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialZ66__BatchAddMaterial__GroupCreat' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialZ66/BatchAddMaterial/GroupCreat'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialZ66/edit/:id",
                "name": "materialZ66.edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialZ66__MaterialEdit' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialZ66/MaterialEdit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialZ66/resourceMandatoryVersionMgr",
                "name": "materialZ66.resourceMandatoryVersionMgr",
                "routes": [
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/materialZ66/resourceMandatoryVersionMgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialZ66__ResourceMandatoryVersionMgr' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialZ66/ResourceMandatoryVersionMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "必选素材版本管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/materialZ66/resourceMandatoryVersionMgr/resourceMandatoryListList/:id",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialZ66__ResourceMandatoryList' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialZ66/ResourceMandatoryList'), loading: LoadingComponent}),
                    "meta": {
                      "name": "素材必选列表"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/materialZ70",
                "name": "materialZ70",
                "routes": [
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/materialZ70/create",
                    "name": "materialZ70.create",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialZ70__MaterialCreate' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialZ70/MaterialCreate'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/materialZ70/edit/:id",
                    "name": "materialZ70.edit",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialZ70__MaterialEdit' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialZ70/MaterialEdit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/rsMarket/materialmanag/materialZ70/resourceMandatoryVersionMgr",
                    "name": "materialZ70.resourceMandatoryVersionMgr",
                    "routes": [
                      {
                        "path": "/value-added-center/rsMarket/materialmanag/materialZ70/resourceMandatoryVersionMgr/",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialZ70__ResourceMandatoryVersionMgr' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialZ70/ResourceMandatoryVersionMgr'), loading: LoadingComponent}),
                        "meta": {
                          "name": "必选素材版本管理"
                        },
                        "exact": true
                      },
                      {
                        "path": "/value-added-center/rsMarket/materialmanag/materialZ70/resourceMandatoryVersionMgr/resourceMandatoryListList/:id",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__MaterialZ70__ResourceMandatoryList' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/MaterialZ70/ResourceMandatoryList'), loading: LoadingComponent}),
                        "meta": {
                          "name": "素材必选列表"
                        },
                        "exact": true
                      }
                    ]
                  }
                ]
              },
              {
                "path": "/value-added-center/rsMarket/materialmanag/commonPage/materialRelationSinger",
                "name": "commonPage.materialRelationSinger",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__MaterialMgr__CommonPage__MaterialRelationSinger' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/MaterialMgr/CommonPage/MaterialRelationSinger'), loading: LoadingComponent}),
                "meta": {
                  "name": "歌星关联素材"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/value-added-center/rsMarket",
            "name": "rsMarket",
            "routes": [
              {
                "path": "/value-added-center/rsMarket/company_list",
                "name": "company_list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CustomerList' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CustomerList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/company_list/company_rsbox_overview/:customerCode",
                "name": "company_rsbox_overview",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyRsboxOverview' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyRsboxOverview'), loading: LoadingComponent}),
                "meta": {
                  "name": "场所盒子"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/agent_balance",
                "name": "agent_balance",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__AgentBalance' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/AgentBalance'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/agent_balance/agent_pay_detail/:agentId",
                "name": "agent_pay_detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__AgentPayDetail' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/AgentPayDetail'), loading: LoadingComponent}),
                "meta": {
                  "name": "充值详情"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/agent_balance/agent_consumption_detail/:agentId",
                "name": "agent_consumption_detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__AgentConsumptionDetail' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/AgentConsumptionDetail'), loading: LoadingComponent}),
                "meta": {
                  "name": "消费详情"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/agent_balance/agent_change_list/:agentId",
                "name": "agent_change_list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__CompanyResource__AgentChangeList' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/CompanyResource/AgentChangeList'), loading: LoadingComponent}),
                "meta": {
                  "name": "变动流水"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/backend-agent/account-list",
                "name": "account-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__BackendAgent__AccountList' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/BackendAgent/AccountList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/backend-agent/order-list",
                "name": "order-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__BackendAgent__OrderList' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/BackendAgent/OrderList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/backend-agent/consume-list",
                "name": "consume-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__BackendAgent__ConsumeList' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/BackendAgent/ConsumeList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/backend-agent/balance-changed-list",
                "name": "balance-changed-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__BackendAgent__BalanceChangedList' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/BackendAgent/BalanceChangedList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/rsbox-private/rs-company-manage",
                "name": "rs-company-manage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__RsboxPrivate__rsCompanyManage' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/RsboxPrivate/rsCompanyManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/rsbox-private/rs-company-operation-log",
                "name": "rs-company-operation-log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__RsboxPrivate__rsCompanyOperationLog' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/RsboxPrivate/rsCompanyOperationLog'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/rsMarket/song_linkage",
                "name": "song_linkage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__RSMarket__SongLinkage' */'/app/my-app/src/pages/ValueAddedCenter/RSMarket/SongLinkage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/value-added-center/magic-cloud-11-homepage-design",
            "routes": [
              {
                "path": "/value-added-center/magic-cloud-11-homepage-design/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloud11Homepage__HomepageDesignMgr' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloud11Homepage/HomepageDesignMgr'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-11-homepage-design/link",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloud11Homepage__Link' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloud11Homepage/Link'), loading: LoadingComponent}),
                "meta": {
                  "name": "功能链接"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-11-homepage-design/link/link-type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloud11Homepage__Link__LinkType' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloud11Homepage/Link/LinkType'), loading: LoadingComponent}),
                "meta": {
                  "name": "功能链接类型"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-11-homepage-design/:productKey/:productName",
                "routes": [
                  {
                    "path": "/value-added-center/magic-cloud-11-homepage-design/:productKey/:productName/homepage-mgr",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloud11Homepage__HomepageMgr' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloud11Homepage/HomepageMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "${productName}首页管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/magic-cloud-11-homepage-design/:productKey/:productName/homepage-mgr/add-skin-bag",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloud11Homepage__EditSkinBag' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloud11Homepage/EditSkinBag'), loading: LoadingComponent}),
                    "meta": {
                      "name": "新增皮肤包"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/magic-cloud-11-homepage-design/:productKey/:productName/homepage-mgr/edit-skin-bag/:skinBagId",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloud11Homepage__EditSkinBag' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloud11Homepage/EditSkinBag'), loading: LoadingComponent}),
                    "meta": {
                      "name": "编辑皮肤包"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/magic-cloud-11-homepage-design/:productKey/:productName/homepage-mgr/show-skin-bag/:skinBagId",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloud11Homepage__EditSkinBag' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloud11Homepage/EditSkinBag'), loading: LoadingComponent}),
                    "meta": {
                      "name": "查看皮肤包"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/magic-cloud-11-homepage-design/:productKey/:productName/color",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloud11Homepage__Color' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloud11Homepage/Color'), loading: LoadingComponent}),
                    "meta": {
                      "name": "${productName}配色表"
                    },
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "path": "/value-added-center/magic-cloud-market",
            "routes": [
              {
                "path": "/value-added-center/magic-cloud-market/",
                "redirect": "/value-added-center/magic-cloud-market/company-list",
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-market/company-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloudMarket__CompanyList' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloudMarket/CompanyList'), loading: LoadingComponent}),
                "meta": {
                  "name": "场所列表"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-market/company-list/device-list/:companyCode",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloudMarket__CompanyList__DeviceList' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloudMarket/CompanyList/DeviceList'), loading: LoadingComponent}),
                "meta": {
                  "name": "盒子列表"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-market/company-list/device-list/:companyCode/skin-mgr/:mac",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloudMarket__CompanyList__SkinMgr' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloudMarket/CompanyList/SkinMgr'), loading: LoadingComponent}),
                "meta": {
                  "name": "皮肤管理"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-market/pilot",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloudMarket__SkinPilot' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloudMarket/SkinPilot'), loading: LoadingComponent}),
                "meta": {
                  "name": "皮肤包试点列表"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-market/pilot/:productKey/show-skin-bag/:skinBagId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloud11Homepage__EditSkinBag' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloud11Homepage/EditSkinBag'), loading: LoadingComponent}),
                "meta": {
                  "name": "查看皮肤包"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-market/pilot/device-list/:skinId",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloudMarket__SkinPilot__PilotDevice' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloudMarket/SkinPilot/PilotDevice'), loading: LoadingComponent}),
                "meta": {
                  "name": "管理试点设备"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-market/shelve",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloudMarket__SkinShelve' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloudMarket/SkinShelve'), loading: LoadingComponent}),
                "meta": {
                  "name": "皮肤包上架列表"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/magic-cloud-market/theme-type-management",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__MagicCloudMarket__SkinShelve__ThemeTypeManagement' */'/app/my-app/src/pages/ValueAddedCenter/MagicCloudMarket/SkinShelve/ThemeTypeManagement'), loading: LoadingComponent}),
                "meta": {
                  "name": "主题类型管理"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/value-added-center/song-operate",
            "routes": [
              {
                "path": "/value-added-center/song-operate/",
                "redirect": "/value-added-center/song-operate/company-list",
                "exact": true
              },
              {
                "path": "/value-added-center/song-operate/company-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__CompanyList' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/CompanyList'), loading: LoadingComponent}),
                "meta": {
                  "name": "场所列表"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/song-operate/company-list/resource-mgr/:companyCode",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__CompanyList__ResourceMgr' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/CompanyList/ResourceMgr'), loading: LoadingComponent}),
                "meta": {
                  "name": "资源管理"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/song-operate/company-list/resource-rollback/:companyCode",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__CompanyList__ResourceRollback' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/CompanyList/ResourceRollback'), loading: LoadingComponent}),
                "meta": {
                  "name": "资源回滚"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/song-operate/company-list/radio-list/:companyCode",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__CompanyList__RaidoList' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/CompanyList/RaidoList'), loading: LoadingComponent}),
                "meta": {
                  "name": "电台列表(${companyCode})"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/song-operate/company-list/radio-list/:companyCode/classify-list",
                "name": "classify-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__CompanyList__RaidoList__ClassifyList' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/CompanyList/RaidoList/ClassifyList'), loading: LoadingComponent}),
                "meta": {
                  "name": "电台分类列表"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/song-operate/company-list/radio-list/:companyCode/song-list/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__CompanyList__RaidoList__SongList' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/CompanyList/RaidoList/SongList'), loading: LoadingComponent}),
                "meta": {
                  "name": "歌曲列表"
                },
                "exact": true
              },
              {
                "path": "/value-added-center/song-operate/resource-mgr",
                "routes": [
                  {
                    "path": "/value-added-center/song-operate/resource-mgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ResourceMgr' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ResourceMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "资源管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/resource-mgr/resource-type",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ResourceMgr__ResourceType' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ResourceMgr/ResourceType'), loading: LoadingComponent}),
                    "meta": {
                      "name": "资源类型管理"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/song-operate/program-mgr",
                "routes": [
                  {
                    "path": "/value-added-center/song-operate/program-mgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ProgramMgr' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ProgramMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "欣赏性节目管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/program-mgr/create",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ProgramMgr__CreateProgram' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ProgramMgr/CreateProgram'), loading: LoadingComponent}),
                    "meta": {
                      "name": "创建节目"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/program-mgr/edit/:programId",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ProgramMgr__EditProgram' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ProgramMgr/EditProgram'), loading: LoadingComponent}),
                    "meta": {
                      "name": "编辑节目"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/program-mgr/program-type",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ProgramMgr__ProgramType' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ProgramMgr/ProgramType'), loading: LoadingComponent}),
                    "meta": {
                      "name": "节目类型管理"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/song-operate/atmosphere-mgr",
                "routes": [
                  {
                    "path": "/value-added-center/song-operate/atmosphere-mgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__AtmosphereMgr' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/AtmosphereMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "场景秀管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/atmosphere-mgr/create/:companyCode",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__AtmosphereMgr__CreateAtmosphere' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/AtmosphereMgr/CreateAtmosphere'), loading: LoadingComponent}),
                    "meta": {
                      "name": "创建场景秀"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/atmosphere-mgr/edit/:atmosphereId/:companyCode",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__AtmosphereMgr__EditAtmosphere' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/AtmosphereMgr/EditAtmosphere'), loading: LoadingComponent}),
                    "meta": {
                      "name": "编辑场景秀"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/atmosphere-mgr/light-type",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__AtmosphereMgr__LightType' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/AtmosphereMgr/LightType'), loading: LoadingComponent}),
                    "meta": {
                      "name": "场景秀灯光管理"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/song-operate/game-video-mgr",
                "routes": [
                  {
                    "path": "/value-added-center/song-operate/game-video-mgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__GameVideoMgr' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/GameVideoMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "游戏视频管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/game-video-mgr/create/:companyCode",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__GameVideoMgr__CreateGameVideo' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/GameVideoMgr/CreateGameVideo'), loading: LoadingComponent}),
                    "meta": {
                      "name": "创建游戏视频"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/game-video-mgr/edit/:atmosphereId/:companyCode",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__GameVideoMgr__EditGameVideo' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/GameVideoMgr/EditGameVideo'), loading: LoadingComponent}),
                    "meta": {
                      "name": "编辑游戏视频"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/song-operate/song",
                "routes": [
                  {
                    "path": "/value-added-center/song-operate/song/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__SongList' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/SongList'), loading: LoadingComponent}),
                    "meta": {
                      "name": "歌曲列表"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/song-operate/combo-list",
                "name": "combo-list",
                "routes": [
                  {
                    "path": "/value-added-center/song-operate/combo-list/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ComboList' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ComboList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/combo-list/combo-song-list/:id",
                    "name": "combo-song-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ComboList__ComboSongList' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ComboList/ComboSongList'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/song-operate/listen-song-list-config",
                "name": "listen-song-list-config",
                "routes": [
                  {
                    "path": "/value-added-center/song-operate/listen-song-list-config/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ListenSongListConfig' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ListenSongListConfig'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/listen-song-list-config/song-list/:id",
                    "name": "song-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ListenSongListConfig__SongList' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ListenSongListConfig/SongList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/song-operate/listen-song-list-config/classify-list",
                    "name": "classify-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongOperate__ListenSongListConfig__ClassifyList' */'/app/my-app/src/pages/ValueAddedCenter/SongOperate/ListenSongListConfig/ClassifyList'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "path": "/value-added-center/billboard-operate",
            "routes": [
              {
                "path": "/value-added-center/billboard-operate/",
                "redirect": "/value-added-center/billboard-operate/billboard-publish-mgr",
                "exact": true
              },
              {
                "path": "/value-added-center/billboard-operate/billboards-tag-mgr",
                "routes": [
                  {
                    "path": "/value-added-center/billboard-operate/billboards-tag-mgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BillboardOperate__BillboardsTagMgr' */'/app/my-app/src/pages/ValueAddedCenter/BillboardOperate/BillboardsTagMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "榜单标签管理"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/billboard-operate/source-billboard-mgr",
                "routes": [
                  {
                    "path": "/value-added-center/billboard-operate/source-billboard-mgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BillboardOperate__SourceBillboardMgr' */'/app/my-app/src/pages/ValueAddedCenter/BillboardOperate/SourceBillboardMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "榜单源数据管理"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/billboard-operate/billboard-mgr",
                "routes": [
                  {
                    "path": "/value-added-center/billboard-operate/billboard-mgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BillboardOperate__BillboardMgr' */'/app/my-app/src/pages/ValueAddedCenter/BillboardOperate/BillboardMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "榜单管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/billboard-operate/billboard-mgr/create",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BillboardOperate__BillboardMgr__CreateBillboard' */'/app/my-app/src/pages/ValueAddedCenter/BillboardOperate/BillboardMgr/CreateBillboard'), loading: LoadingComponent}),
                    "meta": {
                      "name": "创建榜单"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/billboard-operate/billboard-mgr/edit/:billboardId",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BillboardOperate__BillboardMgr__EditBillboard' */'/app/my-app/src/pages/ValueAddedCenter/BillboardOperate/BillboardMgr/EditBillboard'), loading: LoadingComponent}),
                    "meta": {
                      "name": "编辑榜单"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/billboard-operate/billboard-mgr/billboard-song/:billboardId",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BillboardOperate__BillboardMgr__BillboardSong' */'/app/my-app/src/pages/ValueAddedCenter/BillboardOperate/BillboardMgr/BillboardSong'), loading: LoadingComponent}),
                    "meta": {
                      "name": "榜单歌曲列表"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/billboard-operate/billboard-publish-mgr",
                "routes": [
                  {
                    "path": "/value-added-center/billboard-operate/billboard-publish-mgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BillboardOperate__BillboardPublishMgr' */'/app/my-app/src/pages/ValueAddedCenter/BillboardOperate/BillboardPublishMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "榜单发布管理"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/billboard-operate/billboard-publish-mgr/billboard-config/:billboardPublishManagementId",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BillboardOperate__BillboardPublishMgr__BillboardConfig' */'/app/my-app/src/pages/ValueAddedCenter/BillboardOperate/BillboardPublishMgr/BillboardConfig'), loading: LoadingComponent}),
                    "meta": {
                      "name": "榜单发布配置"
                    },
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/billboard-operate/billboard-publish-mgr/billboard-publish-version/:billboardPublishManagementId",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BillboardOperate__BillboardPublishMgr__BillboardPublishVersion' */'/app/my-app/src/pages/ValueAddedCenter/BillboardOperate/BillboardPublishMgr/BillboardPublishVersion'), loading: LoadingComponent}),
                    "meta": {
                      "name": "榜单发布版本"
                    },
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/billboard-operate/song-demand-mgr",
                "routes": [
                  {
                    "path": "/value-added-center/billboard-operate/song-demand-mgr/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__BillboardOperate__SongDemandMgr' */'/app/my-app/src/pages/ValueAddedCenter/BillboardOperate/SongDemandMgr'), loading: LoadingComponent}),
                    "meta": {
                      "name": "歌曲点播率权重设置"
                    },
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "path": "/value-added-center/resource-push-manage",
            "routes": [
              {
                "path": "/value-added-center/resource-push-manage/",
                "redirect": "/value-added-center/resource-push-manage/black-list",
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/black-list",
                "name": "black-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__BlackList' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/BlackList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/customize-list",
                "name": "customize-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__CustomizeList' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/CustomizeList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/company-private-song-list",
                "name": "company-private-song-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__CompanyPrivateSongList' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/CompanyPrivateSongList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/resource",
                "name": "resource-manage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__ResourceManage' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/ResourceManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/resource/experiment-company-mgr",
                "name": "experiment-company-mgr",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__ResourceManage__components__ResourceExperimentMgr' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/ResourceManage/components/ResourceExperimentMgr'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/resource/grounding-mgr",
                "name": "grounding-mgr",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__ResourceManage__components__GroundingMgr' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/ResourceManage/components/GroundingMgr'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/push-qr-code-list",
                "name": "push-qr-code-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__ResourceManage__CompanyQrcodeList' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/ResourceManage/CompanyQrcodeList'), loading: LoadingComponent}),
                "meta": {
                  "name": "二维码推送"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/value-added-center/song-copyright-manage",
            "routes": [
              {
                "path": "/value-added-center/song-copyright-manage/",
                "redirect": "/value-added-center/song-copyright-manage/song-copyright-list",
                "exact": true
              },
              {
                "path": "/value-added-center/song-copyright-manage/song-copyright-list",
                "name": "song-copyright-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongCopyrightManage__SongCopyrightList' */'/app/my-app/src/pages/ValueAddedCenter/SongCopyrightManage/SongCopyrightList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/song-copyright-manage/shop-copyright-auth-list",
                "name": "shop-copyright-auth-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongCopyrightManage__ShopCopyrightAuthList' */'/app/my-app/src/pages/ValueAddedCenter/SongCopyrightManage/ShopCopyrightAuthList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/song-copyright-manage/abnormal-shop-list",
                "name": "abnormal-shop-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__SongCopyrightManage__AbnormalShopList' */'/app/my-app/src/pages/ValueAddedCenter/SongCopyrightManage/AbnormalShopList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/value-added-center/resource-push-manage",
            "routes": [
              {
                "path": "/value-added-center/resource-push-manage/",
                "redirect": "/value-added-center/resource-push-manage/black-list",
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/black-list",
                "name": "black-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__BlackList' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/BlackList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/customize-list",
                "name": "customize-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__CustomizeList' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/CustomizeList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/company-private-song-list",
                "name": "company-private-song-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__CompanyPrivateSongList' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/CompanyPrivateSongList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/resource",
                "name": "resource-manage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__ResourceManage' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/ResourceManage'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/resource/experiment-company-mgr",
                "name": "experiment-company-mgr",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__ResourceManage__components__ResourceExperimentMgr' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/ResourceManage/components/ResourceExperimentMgr'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/resource/grounding-mgr",
                "name": "grounding-mgr",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__ResourceManage__components__GroundingMgr' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/ResourceManage/components/GroundingMgr'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/resource-push-manage/push-qr-code-list",
                "name": "push-qr-code-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ResourcePushManage__ResourceManage__CompanyQrcodeList' */'/app/my-app/src/pages/ValueAddedCenter/ResourcePushManage/ResourceManage/CompanyQrcodeList'), loading: LoadingComponent}),
                "meta": {
                  "name": "二维码推送"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/value-added-center/zhi-kong-center",
            "name": "zhi-kong-center",
            "routes": [
              {
                "path": "/value-added-center/zhi-kong-center/zhi-kong-res-mgr",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__ZhiKongResMgr' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/ZhiKongResMgr'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/value-added-center/zhi-kong-center/zhi-kong-res-mgr/",
                    "redirect": "/value-added-center/zhi-kong-center/zhi-kong-res-mgr/project-list",
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/zhi-kong-center/zhi-kong-res-mgr/project-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__ZhiKongResMgr__ProjectList' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/ZhiKongResMgr/ProjectList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/zhi-kong-center/zhi-kong-res-mgr/light-effect-lib",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__ZhiKongResMgr__LightEffectLib' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/ZhiKongResMgr/LightEffectLib'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/zhi-kong-center/shi-yi-res-mgr",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__ShiYiResMgr' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/ShiYiResMgr'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/value-added-center/zhi-kong-center/shi-yi-res-mgr/",
                    "redirect": "/value-added-center/zhi-kong-center/shi-yi-res-mgr/project-list",
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/zhi-kong-center/shi-yi-res-mgr/project-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__ShiYiResMgr__ProjectList' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/ShiYiResMgr/ProjectList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/zhi-kong-center/shi-yi-res-mgr/light-effect-lib",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__ShiYiResMgr__LightEffectLib' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/ShiYiResMgr/LightEffectLib'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/zhi-kong-center/agent-res-mgr",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__AgentResMgr' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/AgentResMgr'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/value-added-center/zhi-kong-center/agent-res-mgr/",
                    "redirect": "/value-added-center/zhi-kong-center/agent-res-mgr/project-list",
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/zhi-kong-center/agent-res-mgr/project-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__AgentResMgr__ProjectList' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/AgentResMgr/ProjectList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/zhi-kong-center/agent-res-mgr/light-effect-lib",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__AgentResMgr__LightEffectLib' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/AgentResMgr/LightEffectLib'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/zhi-kong-center/lamp-factory-res-mgr",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__LampFactoryResMgr' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/LampFactoryResMgr'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/value-added-center/zhi-kong-center/lamp-factory-res-mgr/",
                    "redirect": "/value-added-center/zhi-kong-center/lamp-factory-res-mgr/project-list",
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/zhi-kong-center/lamp-factory-res-mgr/project-list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__LampFactoryResMgr__ProjectList' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/LampFactoryResMgr/ProjectList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/value-added-center/zhi-kong-center/lamp-factory-res-mgr/light-effect-lib",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__LampFactoryResMgr__LightEffectLib' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/LampFactoryResMgr/LightEffectLib'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/value-added-center/zhi-kong-center/zhi-kong-light-code",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LightCode' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LightCode'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/zhi-kong-center/zhi-kong-light-brand",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__IntelligentControlCenter__LightBrand' */'/app/my-app/src/pages/ValueAddedCenter/IntelligentControlCenter/LightBrand'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/zhi-kong-center/zhi-kong-customer-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__ZhiKongCustomerList' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/ZhiKongCustomerList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/value-added-center/zhi-kong-center/zhi-kong-customer-list/zhi-kong-manage/:customCode",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ValueAddedCenter__ZhiKongCenter__ZhiKongCustomerList__ZhiKongManage' */'/app/my-app/src/pages/ValueAddedCenter/ZhiKongCenter/ZhiKongCustomerList/ZhiKongManage'), loading: LoadingComponent}),
                "meta": {
                  "name": "智控管理"
                },
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/resource-center",
        "name": "resource-center",
        "isTop": true,
        "routes": [
          {
            "path": "/resource-center/music-library",
            "icon": "idcard",
            "name": "music-library",
            "hideInBreadcrumb": true,
            "routes": [
              {
                "path": "/resource-center/music-library/",
                "redirect": "/resource-center/music-library/song-info",
                "exact": true
              },
              {
                "path": "/resource-center/music-library/song-info",
                "name": "song-info",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__SongInfo' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/SongInfo'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/sound-song-info",
                "name": "sound-song-info",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__SoundSongInfo__soundSongInfo' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/SoundSongInfo/soundSongInfo'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/singer-info",
                "name": "singer-info",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__SingerInfo' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/SingerInfo'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/singer-upload",
                "name": "singer-upload",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__SingerUpload' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/SingerUpload'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/song-file",
                "name": "song-file",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__SongFile' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/SongFile'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/song-file-upload",
                "name": "song-file-upload",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__SongFileUpload' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/SongFileUpload'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/intonation-file-upload",
                "name": "intonation-file-upload",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__IntonationFileUpload' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/IntonationFileUpload'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/word-list",
                "name": "word-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__wordList' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/wordList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/banned-song",
                "name": "banned-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__bannedSongList' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/bannedSongList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/song-file-to-word",
                "name": "song-file-to-word",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__songFileToWord' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/songFileToWord'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/ban-song",
                "name": "ban-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__ban__BanSong' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/ban/BanSong'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/ban-singer",
                "name": "ban-singer",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__ban__BanSinger' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/ban/BanSinger'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/ban-inside-company-whitelist",
                "name": "ban-inside-company-whitelist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__ban__BanInsideCompanyWhitelist' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/ban/BanInsideCompanyWhitelist'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/ban-company-whitelist",
                "name": "ban-company-whitelist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__ban__BanCompanyWhitelist' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/ban/BanCompanyWhitelist'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/song-source-info",
                "name": "song-source-info",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__songSourceInfo__index' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/songSourceInfo/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/ban-source-import",
                "name": "ban-source-import",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__banSourceImport__index' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/banSourceImport/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/ban-source-stop",
                "name": "ban-source-stop",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__banSourceStop__index' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/banSourceStop/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/delete-ban-song",
                "name": "delete-ban-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__deleteBanSong__index' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/deleteBanSong/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/ban-agent",
                "name": "ban-agent",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__BanAgent' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/BanAgent'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/ban-agent-singer",
                "name": "ban-agent-singer",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__BanAgentSinger' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/BanAgentSinger'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/song-rank-export",
                "name": "song-rank-export",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__SongRankExport' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/SongRankExport'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/music-setting",
                "name": "music-setting",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__MusicSetting' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/MusicSetting'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/company-ban-task",
                "name": "company-ban-task",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__CompanyBanTask' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/CompanyBanTask'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library/fourk-song-file-map",
                "name": "fourk-song-file-map",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibrary__fourkSongFileMap' */'/app/my-app/src/pages/ResourceCenter/MusicLibrary/fourkSongFileMap'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/resource-center/distribution",
            "icon": "idcard",
            "name": "distribution",
            "hideInBreadcrumb": true,
            "routes": [
              {
                "path": "/resource-center/distribution/",
                "redirect": "/resource-center/distribution/distributed-song",
                "exact": true
              },
              {
                "path": "/resource-center/distribution/distributed-song",
                "name": "distributed-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__Distribution__DistributedSongs' */'/app/my-app/src/pages/ResourceCenter/Distribution/DistributedSongs'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/distribution/ban-standard-song",
                "name": "distribution-ban-standard-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__Distribution__BanStandardSong' */'/app/my-app/src/pages/ResourceCenter/Distribution/BanStandardSong'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/distribution/ban-standard-singer",
                "name": "distribution-ban-standard-singer",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__Distribution__BanStandardSinger' */'/app/my-app/src/pages/ResourceCenter/Distribution/BanStandardSinger'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/resource-center/remote-resource",
            "name": "remote-resource",
            "routes": [
              {
                "path": "/resource-center/remote-resource/",
                "redirect": "/resource-center/remote-resource/classification",
                "exact": true
              },
              {
                "path": "/resource-center/remote-resource/torrent",
                "name": "torrent",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__RemoteResource__Torrent__Index' */'/app/my-app/src/pages/ResourceCenter/RemoteResource/Torrent/Index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/remote-resource/classification",
                "name": "classification",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__RemoteResource__Classification__Index' */'/app/my-app/src/pages/ResourceCenter/RemoteResource/Classification/Index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/remote-resource/vip-song",
                "name": "vip-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__RemoteResource__VipSong__Index' */'/app/my-app/src/pages/ResourceCenter/RemoteResource/VipSong/Index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/remote-resource/recommend-song",
                "name": "recommend-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__RemoteResource__RecommendSong__Index' */'/app/my-app/src/pages/ResourceCenter/RemoteResource/RecommendSong/Index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/remote-resource/song",
                "name": "song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__RemoteResource__Song__Index' */'/app/my-app/src/pages/ResourceCenter/RemoteResource/Song/Index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/remote-resource/song/:classification_id/song",
                "name": "song-show",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__RemoteResource__Song__Song' */'/app/my-app/src/pages/ResourceCenter/RemoteResource/Song/Song'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/remote-resource/yczy-banned-song",
                "name": "yczy-banned-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__RemoteResource__bannedSong' */'/app/my-app/src/pages/ResourceCenter/RemoteResource/bannedSong'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/remote-resource/yczy-upgrade-package",
                "name": "yczy-upgrade-package",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__RemoteResource__yczyUpgradePackage' */'/app/my-app/src/pages/ResourceCenter/RemoteResource/yczyUpgradePackage'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/resource-center/action",
            "icon": "idcard",
            "name": "action",
            "hideInBreadcrumb": true,
            "routes": [
              {
                "path": "/resource-center/action/",
                "redirect": "/resource-center/action/import",
                "exact": true
              },
              {
                "path": "/resource-center/action/import",
                "name": "import",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__Action__Import' */'/app/my-app/src/pages/ResourceCenter/Action/Import'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/action/export",
                "name": "export",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__Action__Export' */'/app/my-app/src/pages/ResourceCenter/Action/Export'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/action/full-music-library-song-work-order",
                "name": "full-music-library-song-work-order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__Action__FullMusicLibrarySongWorkOrder' */'/app/my-app/src/pages/ResourceCenter/Action/FullMusicLibrarySongWorkOrder'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/action/full-music-library-singer-work-order",
                "name": "full-music-library-singer-work-order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__Action__FullMusicLibrarySingerWorkOrder' */'/app/my-app/src/pages/ResourceCenter/Action/FullMusicLibrarySingerWorkOrder'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/resource-center/pad-advert",
            "name": "pad-advert",
            "routes": [
              {
                "path": "/resource-center/pad-advert/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__PadAdvert' */'/app/my-app/src/pages/ResourceCenter/PadAdvert'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/pad-advert/create-activity",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__PadAdvert__CreateActivity' */'/app/my-app/src/pages/ResourceCenter/PadAdvert/CreateActivity'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/pad-advert/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__PadAdvert__Detail' */'/app/my-app/src/pages/ResourceCenter/PadAdvert/Detail'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/resource-center/product-manual",
            "name": "product-manual",
            "routes": [
              {
                "path": "/resource-center/product-manual/",
                "redirect": "/resource-center/product-manual/manual-classification",
                "exact": true
              },
              {
                "path": "/resource-center/product-manual/manual-classification",
                "name": "manual-classification",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__ProductManual__manualClassification' */'/app/my-app/src/pages/ResourceCenter/ProductManual/manualClassification'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/product-manual/manual-content",
                "name": "manual-content",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__ProductManual__manualContent' */'/app/my-app/src/pages/ResourceCenter/ProductManual/manualContent'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/product-manual/create-manual-content",
                "name": "create-manual-content",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__ProductManual__createProductManual' */'/app/my-app/src/pages/ResourceCenter/ProductManual/createProductManual'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/product-manual/create-manual-content/:id",
                "name": "create-manual-content",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__ProductManual__createProductManual' */'/app/my-app/src/pages/ResourceCenter/ProductManual/createProductManual'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/resource-center/updata-song-mgr",
            "name": "updata-song-mgr",
            "routes": [
              {
                "path": "/resource-center/updata-song-mgr/",
                "redirect": "/resource-center/updata-song-mgr/place-env-check",
                "exact": true
              },
              {
                "name": "place-env-check",
                "path": "/resource-center/updata-song-mgr/place-env-check",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__UpdataSongMgr__PlaceEnvCheck' */'/app/my-app/src/pages/ResourceCenter/UpdataSongMgr/PlaceEnvCheck'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/updata-song-mgr/update-place-songs",
                "name": "update-place-songs",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__UpdataSongMgr__updatePlaceSongs__index' */'/app/my-app/src/pages/ResourceCenter/UpdataSongMgr/updatePlaceSongs/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/updata-song-mgr/update-song-statistics",
                "name": "update-song-statistics",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__UpdataSongMgr__updateSongStatistics' */'/app/my-app/src/pages/ResourceCenter/UpdataSongMgr/updateSongStatistics'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/updata-song-mgr/company-song-update-black-songs",
                "name": "company-song-update-black-songs",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__UpdataSongMgr__CompanySongUpdateBlackSongs' */'/app/my-app/src/pages/ResourceCenter/UpdataSongMgr/CompanySongUpdateBlackSongs'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/resource-center/music-library-analysis",
            "name": "music-library-analysis",
            "routes": [
              {
                "path": "/resource-center/music-library-analysis/",
                "redirect": "/resource-center/music-library-analysis/company-song-statistics",
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/company-song-statistics",
                "name": "company-song-statistics",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__companySongStatistics' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/companySongStatistics'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/company-song-statistics-task",
                "name": "company-song-statistics-task",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__companySongStatisticsTask' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/companySongStatisticsTask'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/check-song-ban-status",
                "name": "check-song-ban-status",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__checkSongBanStatus' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/checkSongBanStatus'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/check-singer-ban-status",
                "name": "check-singer-ban-status",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__checkSingerBanStatus' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/checkSingerBanStatus'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/cdn-traffic-statistics",
                "name": "cdn-traffic-statistics",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__cdnTrafficStatistics' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/cdnTrafficStatistics'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/song-data-match",
                "name": "song-data-match",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__SongDataMatch' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/SongDataMatch'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/song-data-match/batch",
                "name": "batch-song-data-match",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__SongDataMatch__BatchSongDataMatch' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/SongDataMatch/BatchSongDataMatch'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/song-demand-rate-analysis",
                "name": "song-demand-rate-analysis",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__SongDemandRateAnalysis' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/SongDemandRateAnalysis'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/three-party-content-data-analysis",
                "name": "three-party-content-data-analysis",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__ThreePartyContentDataAnalysis' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/ThreePartyContentDataAnalysis'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/three-party-content-data-analysis/:id",
                "name": "three-party-content-data-analysis-result",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__ThreePartyContentDataAnalysis__ThreePartyContentDataAnalysisResult' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/ThreePartyContentDataAnalysis/ThreePartyContentDataAnalysisResult'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-analysis/song-copyright-analysis",
                "name": "song-copyright-analysis",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryAnalysis__SongCopyrightAnalysis' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryAnalysis/SongCopyrightAnalysis'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/resource-center/music-library-make-song",
            "name": "music-library-make-song",
            "routes": [
              {
                "path": "/resource-center/music-library-make-song/",
                "redirect": "/resource-center/music-library-make-song/song-make-list",
                "exact": true
              },
              {
                "path": "/resource-center/music-library-make-song/lack-of-song",
                "name": "lack-of-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryMakeSong__lackOfSong' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryMakeSong/lackOfSong'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-make-song/chorus-lack-of-song",
                "name": "chorus-lack-of-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryMakeSong__chorusLackOfSong' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryMakeSong/chorusLackOfSong'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-make-song/poor-of-song",
                "name": "poor-of-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryMakeSong__poorOfSong' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryMakeSong/poorOfSong'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-make-song/rankings",
                "name": "rankings",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryMakeSong__rankings' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryMakeSong/rankings'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-make-song/ktvxg-songs",
                "name": "ktvxg-songs",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryMakeSong__KtvxgSongs' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryMakeSong/KtvxgSongs'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-make-song/song-make-list",
                "name": "song-make-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryMakeSong__songMakeList' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryMakeSong/songMakeList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-make-song/chorus-song-make-list",
                "name": "chorus-song-make-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryMakeSong__chorusSongMakeList' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryMakeSong/chorusSongMakeList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-make-song/song-make-black-song",
                "name": "song-make-black-song",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryMakeSong__blackSong' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryMakeSong/blackSong'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/music-library-make-song/song-make-black-singer",
                "name": "song-make-black-singer",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__MusicLibraryMakeSong__blackSinger' */'/app/my-app/src/pages/ResourceCenter/MusicLibraryMakeSong/blackSinger'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/resource-center/dian-bo-history-statistics",
            "name": "dian-bo-history-statistics",
            "routes": [
              {
                "path": "/resource-center/dian-bo-history-statistics/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__DianBoHistoryStatistics__index' */'/app/my-app/src/pages/ResourceCenter/DianBoHistoryStatistics/index'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/resource-center/song-slimming",
            "name": "song-slimming",
            "routes": [
              {
                "path": "/resource-center/song-slimming/",
                "redirect": "/resource-center/song-slimming/ss-shop-list",
                "exact": true
              },
              {
                "name": "ss-shop-list",
                "path": "/resource-center/song-slimming/ss-shop-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__SongSlimming__SSShopList__index' */'/app/my-app/src/pages/ResourceCenter/SongSlimming/SSShopList/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/song-slimming/ss-song-revocation",
                "name": "ss-song-revocation",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__SongSlimming__SSSongRevocation__index' */'/app/my-app/src/pages/ResourceCenter/SongSlimming/SSSongRevocation/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/resource-center/song-slimming/ss-song-fill",
                "name": "ss-song-fill",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ResourceCenter__SongSlimming__SSSongFill__index' */'/app/my-app/src/pages/ResourceCenter/SongSlimming/SSSongFill/index'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/pay-manage",
        "name": "pay-manage",
        "routes": [
          {
            "path": "/pay-manage/scan-to-start",
            "name": "scan-to-start",
            "routes": [
              {
                "path": "/pay-manage/scan-to-start/",
                "redirect": "/pay-manage/scan-to-start/transaction-record",
                "exact": true
              },
              {
                "path": "/pay-manage/scan-to-start/transaction-record",
                "name": "transaction-record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PayManage__ScanToStart__TransactionRecord' */'/app/my-app/src/pages/PayManage/ScanToStart/TransactionRecord'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/pay-manage/genuine-content-mgr",
            "name": "genuine-content-mgr",
            "routes": [
              {
                "path": "/pay-manage/genuine-content-mgr/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PayManage__GenuineContentMgr__index' */'/app/my-app/src/pages/PayManage/GenuineContentMgr/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/pay-manage/genuine-content-mgr/bill-manage/:versionName/list",
                "name": "bill-manage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PayManage__GenuineContentMgr__BillManage__index' */'/app/my-app/src/pages/PayManage/GenuineContentMgr/BillManage/index'), loading: LoadingComponent}),
                "meta": {
                  "name": "${versionName}账单管理"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/pay-manage/shop-account-mgr",
            "name": "shop-account-mgr",
            "routes": [
              {
                "path": "/pay-manage/shop-account-mgr/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PayManage__ShopAccountMgr__index' */'/app/my-app/src/pages/PayManage/ShopAccountMgr/index'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/pay-manage/shop-account-mgr/shop-order/:id",
                "name": "shop-order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PayManage__ShopAccountMgr__ShopOrder__index' */'/app/my-app/src/pages/PayManage/ShopAccountMgr/ShopOrder/index'), loading: LoadingComponent}),
                "meta": {
                  "name": "流水明细(${id})"
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/pay-manage/shop-withdrawal-review",
            "name": "shop-withdrawal-review",
            "routes": [
              {
                "path": "/pay-manage/shop-withdrawal-review/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PayManage__ShopWithdrawalReview__index' */'/app/my-app/src/pages/PayManage/ShopWithdrawalReview/index'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/exception/500",
        "name": "500",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__500' */'/app/my-app/src/pages/Exception/500'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exception/403",
        "name": "403",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__403' */'/app/my-app/src/pages/Exception/403'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exception/429",
        "name": "429",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__429' */'/app/my-app/src/pages/Exception/429'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exception/empty",
        "name": "empty",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__empty' */'/app/my-app/src/pages/Exception/empty'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
